import styled from "styled-components";
import PropTypes from "prop-types";
import { media, colors } from "utils";

const Flex = styled.div`
  display: flex;
  background: ${({ background }) => colors[background] || background};
  flex-direction: ${({ column }) => column && "column"};
  justify-content: ${({ justify = "space-between" }) => justify};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  align-items: ${({ align = "center" }) => align};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  min-height: ${({ minHeight }) => minHeight};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  border-top: ${({ borderTop }) => borderTop};
  border-bottom: ${({ borderBottom }) => borderBottom};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  z-index: ${({ zIndex }) => zIndex};
  cursor: ${({ cursor }) => cursor};
  color: ${({ color }) => colors[color] || color};
  box-shadow: ${({ boxShadow }) => boxShadow};
  gap: ${({ gap }) => gap};
  overflow: ${({ overflow }) => overflow};
  overflow-y: ${({ overflowY }) => overflowY};
  overflow-x: ${({ overflowX }) => overflowX};

  &.columnToRow {
    flex-direction: column;
    align-items: flex-start;
    ${media.tablet`
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    `}
  }

  &.columnToRowTabletPlus {
    flex-direction: column;
    align-items: flex-start;
    ${media.tabletPlus`
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    `}
  }

  &.columnToRowPlus {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    ${media.tablet`
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &.spaceBetween {
        justify-content: space-between;
      }
    `}
  }

  &.mobileCenter {
    justify-content: center;
    align-items: center;
    ${media.tablet`
      justify-content: space-between;
    `}
  }

  &.rowFormInput {
    background: ${colors.WHITE};
    box-shadow: 3px 0 5px 0 rgba(142, 142, 142, 0.75);
  }

  &.centerModal {
    flex-direction: column;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #00000080;
    z-index: 99999;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    padding: 0 0 0 280px;
    ${media.tablet`
    padding: 0;
    `}
  }

  ${({ responsive }) => {
    if (responsive === "columnToRowAtLaptop") {
      return `
        // flex-direction: column;
        ${media.laptop`
          flex-direction: row;
        `}
      `;
    }
  }}
`;

Flex.propTypes = {
  /** If "column" is passed, flex-direction will be set to column, otherwise it will default to row */
  column: PropTypes.bool,
  /** If present it sets the "justify-content" flexbox property--accepts standard flexbox values such as "space-around" */
  justify: PropTypes.string,
  /** If present it sets the "align-items" flexbox property--accepts standard flexbox values such as "flex-start" */
  align: PropTypes.string,
  /** If flexWrap is passed, it will set the flex-wrap property to the value passed otherwise it will use the default css value */
  flexWrap: PropTypes.string,
  /** If present it sets the width of the element; takes any CSS width value (pixels, percentage, etc.) */
  width: PropTypes.string,
  /** If present it sets the height of the element; takes any CSS height value (pixels, percentage, etc.) */
  height: PropTypes.string,
  /** If present it sets the padding of the element; takes any CSS padding value (pixels, EMs, etc.) */
  padding: PropTypes.string,
  /** If present it sets the margin of the element; takes any CSS margin value (pixels, EMs, etc.) */
  margin: PropTypes.string,
  /** If present it sets the maxWidth of the element; takes any CSS maxWidth value (pixels, percentage, etc.) */
  maxWidth: PropTypes.string,
};

Flex.defaultProps = {
  column: false,
  justify: "space-between",
  align: "center",
  flexWrap: "nowrap",
};

/** @component */
export default Flex;
