import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import * as Styled from "./Chart.styled";
import { format } from "date-fns";
import Tooltip from "./Tooltip";
import * as Ticks from "./Ticks";
import Legend from "./Legend";
import { useChartWidth } from "./chartUtils";

const Bar = ({
  keys,
  data,
  colors,
  legend,
  stacked,
  indexBy = "utc",
  cardWidth,
  axisLeftLegend,
  axisBottomLegend,
}) => {
  const chartWidth = useChartWidth({
    dataLength: data.length,
    margin: 190,
    cardWidth,
  });

  return (
    <Styled.WidthProvider width={chartWidth}>
      <Styled.HeightProvider height="320px">
        <ResponsiveBar
          data={data}
          {...(stacked && { groupMode: "stacked" })}
          indexBy={indexBy}
          colors={colors}
          keys={keys}
          padding={0.3}
          margin={{ top: 10, right: 50, bottom: 70, left: 60 }}
          enableGridX={true}
          enableGridY={true}
          enableLabel={false}
          layout="vertical"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: -30,
            tickPadding: 30,
            tickRotation: 0,
            legend: axisBottomLegend,
            legendPosition: "middle",
            legendOffset: 60,
            renderTick: Ticks.X,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 90,
            renderTick: Ticks.Y,
            legend: axisLeftLegend,
            legendPosition: "middle",
            legendOffset: -50,
          }}
          tooltip={({ id, indexValue, value }) => {
            return (
              <Tooltip
                label={id.replace("_", " ")}
                date={format(new Date(indexValue), "LLL do")}
                value={value}
              />
            );
          }}
          theme={{
            axis: {
              ticks: {
                line: {
                  strokeWidth: 1,
                  stroke: "#dddddd",
                },
              },
              legend: {
                text: {
                  fill: "#3F4F58",
                  fontWeight: "900",
                  fontSize: "18px",
                },
              },
            },
            tooltip: {
              container: {
                background: "transparent",
                boxShadow: "0",
              },
            },
          }}
        />
      </Styled.HeightProvider>
      {legend && <Legend {...{ legend }} />}
    </Styled.WidthProvider>
  );
};

Bar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.array,
};

export default Bar;
