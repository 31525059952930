import styled from "styled-components";
import { colors } from "utils";

export const Error = styled.span`
  color: ${colors.RED_200};
  font-size: 1rem;
  margin-right: 0.5rem;
  &::after {
    content: ",";
  }
`;
