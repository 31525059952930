import React from "react";
import { useTable, useSortBy, useFlexLayout } from "react-table";
import * as Styled from "./Table.styled";

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "flex-start") => {
  return [
    props,
    {
      style: {
        justifyContent: align,
        alignItems: "flex-start",
        display: "flex",
      },
    },
  ];
};

const Table = ({ columns, data, minHeight, maxWidth, className }) => {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 200,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: "fh_display",
          },
        ],
      },
    },
    useSortBy,
    useFlexLayout
  );

  return (
    <Styled.TableContainer
      minHeight={minHeight}
      height={minHeight}
      maxWidth={maxWidth}
      width="100%"
    >
      <Styled.TableWrapper {...getTableProps()}>
        <Styled.TableHead>
          {headerGroups.map((headerGroup) => (
            <Styled.HeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Styled.ColumnHeader {...column.getHeaderProps(headerProps)}>
                  {column.render("Header")}
                </Styled.ColumnHeader>
              ))}
            </Styled.HeaderRow>
          ))}
        </Styled.TableHead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <Styled.TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (index === rows.length - 1) {
                    return (
                      <Styled.RowData
                        borderBottom={false}
                        {...cell.getCellProps(cellProps)}
                      >
                        {cell.render("Cell")}
                      </Styled.RowData>
                    );
                  }
                  return (
                    <Styled.RowData {...cell.getCellProps(cellProps)}>
                      {cell.render("Cell")}
                    </Styled.RowData>
                  );
                })}
              </Styled.TableRow>
            );
          })}
        </tbody>
      </Styled.TableWrapper>
    </Styled.TableContainer>
  );
};

export default Table;
