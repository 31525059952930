import styled from "styled-components";
import { colors } from "utils";

export default styled.div`
  position: absolute;
  top: 52px;
  right: 12px;
  width: 250px;
  padding: 12px;
  background: ${colors.WHITE};
  box-shadow: 0px 0px 4px 1px rgba(199, 199, 199, 0.8);
  z-index: 3;
`;
