export default {
  _25: ".4rem",
  _50: ".8rem",
  _75: "1rem",
  _100: "1.2rem",
  _150: "1.4rem",
  _200: "1.6rem",
  _250: "2rem",
  _300: "2.4rem",
  _400: "2.8rem",
  _450: "3.2rem",
  _500: "3.6rem",
  _550: "4rem",
  _600: "4.8rem",
  _700: "5.6rem",
  _800: "6.4rem",
  _900: "9.6rem",
  _1000: "12.8rem",
};
