import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import { Flex } from "components";

const StyleWrapper = styled(Flex)`
  width: 100%;
  .active {
    display: flex;

    > div {
      font-weight: bold;
      visibility: visible;
    }
  }
  a:-webkit-any-link {
    color: unset;
  }
`;

const StyleLink = styled(Link)`
  display: flex;
  flex-direction: row;
`;

const checkMatch = ({ isCurrent, isPartiallyCurrent, partial }) => {
  return (
    ((partial && isPartiallyCurrent) || isCurrent) && { className: "active" }
  );
};

export default ({ children, partial, ...rest }) => (
  <StyleWrapper>
    <StyleLink
      getProps={(props) => checkMatch({ ...props, partial: partial })}
      {...rest}
    >
      {children}
    </StyleLink>
  </StyleWrapper>
);
