import { format, isWeekend } from "date-fns";

const reportDayAndYear = () => {
  const currentDay = format(new Date(), "P").split("/")[1];
  const currentMonth = format(new Date(), "MMMM");

  // Edge case:  March 29, 30, 31 doesn't allow going back to February
  // If current date is March 29, 30, or 31 set Month to February
  let latestReportingMonth =
    currentMonth === "March" && currentDay > 28
      ? "February"
      : currentDay < 5 && isWeekend(new Date()) // Edge case:  If current date is less than 5 (the 5th of the month) and it is the weekend, set Month to previous month
      ? format(new Date().setMonth(new Date().getMonth() - 2), "MMMM")
      : format(new Date().setMonth(new Date().getMonth() - 1), "MMMM");

  let latestReportingYear;
  // Edge case:  If current date is January 1 - February 4, set Year to previous year

  if (
    (latestReportingMonth === "November" ||
      latestReportingMonth === "December") &&
    (currentMonth === "January" ||
      (currentMonth === "February" && currentDay < 5 && isWeekend(new Date()))) // Edge case:  If current date is January 1 - February 4, set Year to previous year
  ) {
    const unformatted_year = new Date().setYear(new Date().getFullYear() - 1);
    latestReportingYear = format(unformatted_year, "yyyy");
  } else {
    latestReportingYear = format(new Date(), "yyyy");
  }

  return {
    currentDay,
    latestReportingMonth,
    latestReportingYear,
  };
};

export default reportDayAndYear;
