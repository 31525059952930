import React from "react";
import styled from "styled-components";
import { ReactComponent as ExitIcon } from "assets/icons/exit.svg";
import { Flex } from "components";
import { colors } from "utils";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  .relative {
    position: relative;
  }
`;

const ScrollContainer = styled.div`
  max-height: 90%;
  overflow: ${({ overflow = "scroll" }) => overflow};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};
`;

export const Exit = styled(ExitIcon)`
  position: absolute;
  right: 18px;
  top: 20px;
  width: 15px;
  cursor: pointer;
`;

const Box = styled(Flex)`
  background: ${colors.WHITE};
  border-radius: 5px;
  padding: 24px;
  float: left;
  margin: 8px 0;
  min-width: 312px;
  position: relative;
  max-width: ${({ width = "auto" }) => width};
`;

const GenericModal = ({
  children,
  width,
  className,
  close,
  overflow,
  overflowX,
  overflowY,
  ...props
}) => {
  return (
    <Wrapper>
      <ScrollContainer {...{ overflow, overflowX, overflowY }}>
        <Box
          {...(width && { width })}
          {...(className && { className })}
          {...props}
        >
          {close && <Exit onClick={() => close()} />}
          {children}
        </Box>
      </ScrollContainer>
    </Wrapper>
  );
};

export default GenericModal;
