import styled, { keyframes } from "styled-components";
import { colors, media } from "utils";
import { Text } from "components";

const rotate = keyframes`
  0% { opacity: 0 }
  50% { opacity: 1 }
`;

const pulseAnimation = keyframes`
  0% {
    background: ${colors.NEUTRAL_20};
  }
  50% {
    background: ${colors.WHITE};
  }
  100% {
    background: ${colors.NEUTRAL_20};
  }
`;

const borderAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  50% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

export const SkeletonWrapper = styled.div`
  animation: ${rotate} 500ms ease-in-out;
  width: 100%;
  ${media.tabletPlus`
    width: calc(100% - 256px);
  `}
  top: 88px;
  ${media.tabletPlus`
    left: 256px;
  `}
  bottom: 0;
  position: fixed;
  background: ${colors.BASE};
  overflow-x: scroll;
  z-index: 999;
  .body {
    padding: 40px 40px 64px;
    z-index: 999998;
  }
  .footer {
    background: ${colors.NEUTRAL_20};
  }
`;

export const SkeletonInfo = styled(Text)`
  /* animation: ${pulseAnimation} 1000ms infinite; */
  animation-duration: infinite;
  animation-iteration-count: infinite;
  background: ${colors.NEUTRAL_20};
  height: 16px;
  width: 250px;
  border-radius: 4px;
  margin: 0 12px;
  &.large {
    width: 668px;
    margin: 12px 0 0 4px;
  }
`;

export const SkeletonCard = styled.div`
  /* animation: ${pulseAnimation} 1000ms infinite; */
  background: ${colors.WHITE};
  border-radius: 4px;
  padding: 24px;
  float: left;
  margin: 8px 0;
  width: 312px;
  box-shadow: rgba(0, 20, 30, 0.2) 0px 4px 4px -2px;
  position: relative;
  height: 158px;
`;

export const SkeletonButton = styled.button`
  /* animation: ${borderAnimation} 1000ms infinite; */
  display: inline-block;
  background: none;
  text-decoration: none;
  border: 1px solid ${colors.NEUTRAL_50};
  border-radius: 4px;
  cursor: pointer;
  padding: 12px 62px;
  font-size: 1rem;
  text-align: center;
  line-height: 1em;
  opacity: 1;
  margin: 0 4px;
`;
