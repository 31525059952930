import { format, isWeekend } from "date-fns";
import { months } from "consts";

const generateMonths = (baseMonth, baseYear) => {
  if (!baseYear) {
    baseYear = format(
      new Date().setFullYear(new Date().getFullYear() - 2),
      "yyyy"
    );
  }

  if (!baseMonth) {
    baseMonth = format(new Date().setMonth(new Date().getMonth() - 1), "MMMM");
  }

  let menuItems = [];
  const thisMonth = `${format(new Date(), "MMMM")} ${format(
    new Date(),
    "yyyy"
  )}`;

  const currentDay = format(new Date(), "P").split("/")[1];

  const baseMonthIndex = months.findIndex((month) => month === baseMonth);

  const addMonths = (addYear) => {
    if (menuItems.includes(thisMonth)) {
      return;
    } else {
      months.forEach((month, index) => {
        if (addYear > 0) {
          if (!menuItems.includes(thisMonth))
            menuItems.push(`${month} ${parseInt(baseYear) + addYear}`);
        } else {
          if (index >= baseMonthIndex)
            menuItems.push(`${month} ${parseInt(baseYear) + addYear}`);
        }
      });
      addMonths(addYear + 1);
    }
  };

  addMonths(0);
  // remove this month from the list
  menuItems.pop(thisMonth);

  // remove the last month if the current day is less than 5 (the 5th of the month) and it is the weekend, remove the last month
  // this is to prevent the user from selecting a range that is not complete
  if (currentDay < 5 && isWeekend(new Date())) {
    menuItems.pop();
  }
  return menuItems;
};

export default generateMonths;
