import React from "react";
import styled from "styled-components";
import { colors, media, sizing } from "utils";

const Title = styled.h1`
  color: ${({ color }) => colors[color] || colors.neutralBlack};
  font-size: ${sizing._600};
  font-weight: bold;
  line-height: 1;
  ${media.tablet`
    font-size: ${sizing._700};
  `}
`;

const Body = styled.div`
  display: ${({ display }) => display};
  background: ${({ background }) => colors[background] || background};
  color: ${({ color }) => colors[color] || color};
  text-align: ${({ align }) => align};
  text-transform: ${({ transform }) => transform};
  opacity: ${({ opacity }) => opacity};
  font-weight: ${({ weight }) => weight};
  line-height: ${({ line }) => line || "1.4"};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  font-size: ${({ size }) => sizing[size] || size};
  width: ${({ width = "auto" }) => width};
  white-space: ${({ whiteSpace }) => whiteSpace};
  text-wrap${({ wrap }) => wrap};
  cursor: ${({ cursor }) => cursor};
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  border: ${({ border }) => border};
  align-self: ${({ alignSelf }) => alignSelf};
  z-index: ${({ zIndex }) => zIndex};

  &:hover {
    ${({ hover }) => (hover ? hover : "none")};
  }

  &.f2 {
    font-size: ${sizing._500};
    font-weight: bold;
    line-height: 1;
    color: ${({ color }) => colors[color]};
    ${media.tablet`
      font-size: ${sizing._600};
    `}
  }
  &.f3 {
    font-size: ${sizing._550};
  }
  &.f35 {
    font-size: ${sizing._450};
  }
  &.f4 {
    font-size: ${sizing._300};
    color: ${({ color }) => colors[color]};
    ${media.tablet`
      font-size: ${sizing._400};
    `}
  }

  &.f45 {
    font-size: ${sizing._400};
  }

  &.f5 {
    font-size: ${sizing._250};
    color: ${({ color }) => colors[color]};
    ${media.tablet`
      color: ${({ color }) => colors[color]};
      font-size: ${sizing._300};
    `}
  }
  &.f6 {
    font-size: ${sizing._200};
    ${media.tablet`
      font-size: ${sizing._250};
    `}
  }
  &.f7 {
    font-size: ${sizing._150};
    ${media.tablet`
      font-size: ${sizing._200};
    `}
  }
  &.f8 {
    font-size: ${sizing._150};
  }
  &.f9 {
    font-size: ${sizing._100};
  }
  &.f10 {
    font-size: ${sizing._75};
  }
  &.b {
    font-weight: bold;
  }
  &.serif {
    font-family: serif;
  }
  &.underline {
    text-decoration: underline;
    &:hover {
      color: ${({ hoverColor }) => hoverColor};
    }
  }

  &.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.nowrap {
    white-space: nowrap;
  }

  &.mobileCenter {
    text-align: center;
    width: 100%;
    ${media.tablet`
      text-align: left;
      width: auto;
    `}
  }

  /* Alignment classes */
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &.italic {
    font-style: italic;
  }
  &.serif {
    font-family: "PT Serif";
  }
  &.manrope {
    font-family: "Manrope", sans-serif;
  }
  &.inline {
    display: inline;
  }
`;

const Text = ({ kind, className, ...props }) =>
  kind === "h1" ? (
    <Title className={`${kind} ${className}`} {...props}>
      {props.children}
    </Title>
  ) : (
    <Body className={`${kind} ${className}`} {...props}>
      {props.children}
    </Body>
  );

export default Text;
