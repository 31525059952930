import styled from "styled-components";
import { colors, media } from "utils";

export const Sidebar = styled.div`
  width: 256px;
  background: ${colors.BLUE_130};
  height: 100vh;
  overflow: scroll;
  position: fixed;
  z-index: 9999;
  &.hide-in-mobile {
    display: none;
    ${media.tabletPlus`
      display: block;
    `}
  }
`;

export const Head = styled.div`
  height: 88px;
  padding-left: 30px;
`;

export const Menu = styled.div`
  padding: 52px 30px;
`;

export const MenuHead = styled.div`
  &.selectable {
    &:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }
`;

export const MenuItem = styled.div`
  display: flex;
  padding: 12px 16px 12px 12px;
  margin: 4px 0;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
  &.active {
    color: ${colors.GOLD};

    > div {
      visibility: visible;
    }
  }
  &.no-indent {
    padding: 12px 16px 12px 0px;
  }
`;

export const MenuIcon = styled.div`
  margin-right: 32px;
  cursor: pointer;
  font-size: 32px;
  z-index: 999;
  filter: invert(100%);
`;

export const SelectContainer = styled.div`
  position: relative;
  z-index: 0;
`;

export const Select = styled.select`
  position: relative;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-background-color: rgba(0, 0, 0, 0.5);
  -moz-background-color: rgba(0, 0, 0, 0.5);
  color: ${colors.WHITE};
  padding: 18px 32px 18px 8px;
  cursor: pointer;
  border-left: none;
  border-right: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 10;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:active,
  &:focus {
    outline: none;
    border-left: none;
    border-right: none;
  }
`;

export const SelectOption = styled.option`
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-background-color: rgba(0, 0, 0, 0.5);
  -moz-background-color: rgba(0, 0, 0, 0.5);
  color: ${colors.WHITE};
`;

export const Search = styled.input`
  padding: 12px 0 12px 40px;
  background: ${colors.NEUTRAL_75};
  outline: none;
  border: 1px solid gray;
  border-radius: 2px;
  color: ${colors.WHITE};
`;

export const MenuActiveBar = styled.div`
  height: 30px;
  width: 5px;
  margin: 10px;
  border-radius: 2px;
  background-color: ${colors.GOLD_100};

  visibility: ${({ visibility = "hidden" }) => visibility};
`;
