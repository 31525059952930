import React from "react";
import { useTheme } from "@nivo/core";
import { getDate } from "date-fns";
import { monthsObject } from "consts";

export const Y = ({ value, ...tick }) => {
  const theme = useTheme();
  return value % 1 === 0 ? (
    <g transform={`translate(${tick.x - 20},${tick.y - 2})`}>
      <line
        x2={tick.lineX}
        x1={0}
        y2={tick.lineY}
        y1={0}
        style={{ ...theme.axis.ticks.line }}
      />
      <rect x={-12} y={-12} rx={2} ry={2} width={24} height={12} fill="#fff" />
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          ...theme.axis.ticks.text,
          fill: "#7F898E",
          fontSize: 14,
        }}
      >
        {value}
      </text>
    </g>
  ) : (
    <></>
  );
};

export const X = (tick) => {
  const dateValueAsNumber = getDate(new Date(tick.value));

  const theme = useTheme();
  if (dateValueAsNumber % 5 === 0 || dateValueAsNumber === 1)
    return (
      <g transform={`translate(${tick.x},${tick.y + 24})`}>
        <line
          x2={tick.lineX}
          x1={0}
          y2={tick.lineY}
          y1={0}
          style={{ ...theme.axis.ticks.line }}
        />
        <rect
          x={-12}
          y={-12}
          rx={2}
          ry={2}
          width={24}
          height={24}
          fill="#fff"
        />
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
            fill: "#7F898E",
            fontSize: 14,
          }}
        >
          {dateValueAsNumber}
        </text>
      </g>
    );
  return null;
};

export const XByMonth = (tick) => {
  const dateValueAsMonth = monthsObject.find(
    ({ number }) => number === `${tick.value.split("-")[1]}`
  )?.shortName;

  const theme = useTheme();
  return (
    <g transform={`translate(${tick.x},${tick.y + 24})`}>
      <line
        x2={tick.lineX}
        x1={0}
        y2={tick.lineY}
        y1={0}
        style={{ ...theme.axis.ticks.line }}
      />
      <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="#fff" />
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          ...theme.axis.ticks.text,
          fill: "#7F898E",
          fontSize: 14,
        }}
      >
        {dateValueAsMonth}
      </text>
    </g>
  );
};
