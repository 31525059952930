import React, { useState, useEffect } from "react";
// Components
import { Flex, ButtonIcon, Text } from "components";
// Tooltip
import {
  BiChevronsLeft,
  BiChevronLeft,
  BiChevronsRight,
  BiChevronRight,
} from "react-icons/bi";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
// Utils
import { colors } from "utils";

const Pagination = ({
  type,

  // React Table Pagination
  pageOptions,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  gotoPage,
  nextPage,
  previousPage,

  // Backend - Pagination
  fhPagination: { resultsCount, pageSize, pagination, setPagination },
}) => {
  /**
   * logicOwner represents the brain behind any of the logic
   * needed for different types of tables
   *
   * this allows for a single function name to be used that is first
   * appended by its 'owner' aka: react-table
   *
   * TODO(?): this could get extracted into its own file
   *
   * ex:
   *  - Goal: navigate to page 2
   *
   * 🚨 - each key in the logicOwner must be an expected type of table
   */
  const logic = {
    "react-table": {
      goToPage1: () => gotoPage(0),
      previousPage: () => previousPage(),
      nextPage: () => nextPage(),
      lastPage: ({ ...args }) => gotoPage(args.pageCount - 1),
    },
    backend: {
      goToPage1: () => setPagination(1),
      previousPage: (pagination) =>
        pagination !== 1 && setPagination(pagination - 1),
      nextPage: ({ ...args }) =>
        args.lastPage !== args.pagination && setPagination(args.pagination + 1),
      lastPage: ({ ...args }) => setPagination(args.lastPage),
    },
  };

  // state
  const [owner, setOwner] = useState(null);
  const [lastPage, setLastPage] = useState(null);

  // useEffect
  useEffect(() => {
    switch (type) {
      case "funeralHomes":
        setOwner("backend");
        break;
      default:
        setOwner("react-table");
        break;
    }
  }, [type]);
  useEffect(() => {
    setLastPage(Math.ceil(resultsCount / pageSize));
  }, [resultsCount, pageSize]);

  return (
    <Flex
      align="center"
      justify="center"
      className="pagination"
      margin="16px 0"
      width="100%"
    >
      <span>
        <Tooltip
          placement="bottom"
          overlay={<span>first</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          overlayStyle={
            (pagination !== 1 || !canPreviousPage) && { display: "none" }
          }
        >
          <ButtonIcon
            display="flex"
            justify="center"
            align="center"
            onClick={(e) => {
              e.stopPropagation();
              logic[owner].goToPage1();
            }}
            padding="2px 3px"
            margin="0 4px"
            kind="outline square"
            changeIcon={<BiChevronsLeft />}
            defaultIcon={<BiChevronsLeft />}
            color={
              pagination !== 1 || canPreviousPage
                ? colors.GOLD
                : colors.NEUTRAL_05
            }
            cursor={pagination !== 1 || canPreviousPage ? "pointer" : "default"}
            size="2.5rem"
          />
        </Tooltip>
      </span>

      <span>
        <Tooltip
          placement="bottom"
          overlay={<span>previous</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          overlayStyle={
            (pagination !== 1 || !canPreviousPage) && { display: "none" }
          }
        >
          <ButtonIcon
            display="flex"
            justify="center"
            align="center"
            onClick={(e) => {
              e.stopPropagation();
              logic[owner].previousPage(pagination);
            }}
            padding="2px 3px"
            margin="0 4px"
            kind="outline square"
            changeIcon={<BiChevronLeft />}
            defaultIcon={<BiChevronLeft />}
            color={
              pagination !== 1 || canPreviousPage
                ? colors.GOLD
                : colors.NEUTRAL_05
            }
            cursor={pagination !== 1 || canPreviousPage ? "pointer" : "default"}
            size="2.5rem"
          />
        </Tooltip>
      </span>

      <span style={{ margin: "0 8px" }}>
        <strong>
          {type === "funeralHomes" ? (
            <Text>
              {pagination} of {lastPage}
            </Text>
          ) : (
            <Text>
              {pageIndex + 1} of {pageOptions.length}
            </Text>
          )}
        </strong>
      </span>

      <span>
        <Tooltip
          placement="bottom"
          overlay={<span>next</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          overlayStyle={
            (pagination === lastPage || canNextPage) && { display: "none" }
          }
        >
          <ButtonIcon
            display="flex"
            justify="center"
            align="center"
            onClick={(e) => {
              e.stopPropagation();
              logic[owner].nextPage({ pagination, lastPage });
            }}
            padding="2px 3px"
            margin="0 4px"
            kind="outline square"
            changeIcon={<BiChevronRight />}
            defaultIcon={<BiChevronRight />}
            color={
              pagination !== lastPage || canNextPage
                ? colors.GOLD
                : colors.NEUTRAL_05
            }
            cursor={
              pagination !== lastPage || canNextPage ? "pointer" : "default"
            }
            size="2.5rem"
          />
        </Tooltip>
      </span>

      <span>
        <Tooltip
          placement="bottom"
          overlay={<span>last</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          overlayStyle={
            (pagination === lastPage || canNextPage) && { display: "none" }
          }
        >
          <ButtonIcon
            display="flex"
            justify="center"
            align="center"
            onClick={(e) => {
              e.stopPropagation();
              const lastPage = Math.ceil(resultsCount / pageSize);
              logic[owner].lastPage({ pageCount, lastPage });
            }}
            padding="2px 3px"
            margin="0 4px"
            kind="outline square"
            changeIcon={<BiChevronsRight />}
            defaultIcon={<BiChevronsRight />}
            color={
              pagination !== lastPage || canNextPage
                ? colors.GOLD
                : colors.NEUTRAL_05
            }
            cursor={
              pagination !== lastPage || canNextPage ? "pointer" : "default"
            }
            size="2.5rem"
          />
        </Tooltip>
      </span>
    </Flex>
  );
};

export default Pagination;
