import React from "react";
import { InputRow } from "components";
import styled from "styled-components";
import { colors } from "utils";

const Select = styled.select`
  height: 32px;
  width: ${({ width }) => width};
  min-width: 120px;
  max-width: 190px;
  margin: 8px 4px;
  border: none;
  background: ${colors.NEUTRAL_04};
  border-radius: 5px;
`;

const Option = styled.option`
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 300;
  color: ${colors.GOLD_100};
  padding-left: 12px;
  &:disabled {
    color: ${colors.WHITE};
    background-color: ${colors.NEUTRAL_20};
  }
`;

function DateInput({ register }) {
  return (
    <>
      <InputRow
        list="suggestedYears"
        type="number"
        name="year"
        min="1999"
        max="2100"
        placeholder="Year"
        register={register({
          required: { value: true, message: "Year is required" },
          valueAsNumber: true,
        })}
      />

      <datalist id="suggestedYears">
        <option value="2030" />
        <option value="2029" />
        <option value="2028" />
        <option value="2027" />
        <option value="2026" />
        <option value="2025" />
        <option value="2024" />
        <option value="2023" />
        <option value="2022" />
        <option value="2021" />
        <option value="2020" />
        <option value="2019" />
      </datalist>

      <Select
        type="text"
        name="month"
        defaultValue=""
        ref={register({
          required: { value: true, message: "Month is required" },
        })}
      >
        <Option value="" disabled className="month">
          Month
        </Option>
        <Option value="1"> January </Option>
        <Option value="2"> February </Option>
        <Option value="3"> March </Option>
        <Option value="4"> April </Option>
        <Option value="5"> May </Option>
        <Option value="6"> June </Option>
        <Option value="7"> July </Option>
        <Option value="8"> August </Option>
        <Option value="9"> September </Option>
        <Option value="10"> October </Option>
        <Option value="11"> November </Option>
        <Option value="12"> December </Option>
      </Select>
    </>
  );
}

export default DateInput;
