import { useEffect, useState } from "react";
import { colors } from "utils";

const dictionary = [
  { value: "local_spotlight", label: "Local Spotlight", color: colors.BLUE },
  { value: "newspaper", label: "Newspaper", color: colors.BLUE_120 },
  { value: "legacy_pro", label: "Legacy Pro", color: colors.GOLD },
];

const usePrepareCharts = () => {
  const [values, setValues] = useState({});

  //   const keys = useMemo(() => {
  //     return monthlyTotals.flatMap(({ type, value }) => (value > 0 ? type : []));
  //   }, [monthlyTotals]);

  //   const setColors = useCallback((activeKeys) => {
  //     return dictionary
  //       .filter(({ value }) => activeKeys.includes(value))
  //       .flatMap(({ color }) => color);
  //   }, []);

  //   const setAccessoryValues = useCallback((key) => {
  //     return dictionary.find(({ value }) => key === value)
  //   }, [])

  //   const legend = useMemo(() => {
  //     return dictionary.filter(({ value }) => keys.includes(value));
  //   }, []);

  useEffect(() => {
    setValues({
      //   keys: keys,
      //   setColors: setColors,
      //   setAccessoryValues: setAccessoryValues,
      //   legend: legend,
      dictionary: dictionary,
    });
  }, []);

  return values;
};

export default usePrepareCharts;
