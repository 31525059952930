import styled from "styled-components";

export default styled.div`
  display: grid;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  grid-template-columns: ${({ gridColumns }) => gridColumns};
  grid-template-rows: ${({ gridRows }) => gridRows};
  grid-gap: ${({ gridGap = "15px" }) => gridGap};
  justify-content: ${({ justify = "flex-start" }) => justify};
  align-items: ${({ align = "stretch" }) => align};
  width: 100%;
`;
