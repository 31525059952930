const states = [
  {
    label: "Alabama",
    value: "AL",
    country: "United States",
  },
  {
    label: "Alaska",
    value: "AK",
    country: "United States",
  },
  {
    label: "Arizona",
    value: "AZ",
    country: "United States",
  },
  {
    label: "Arkansas",
    value: "AR",
    country: "United States",
  },
  {
    label: "California",
    value: "CA",
    country: "United States",
  },
  {
    label: "Colorado",
    value: "CO",
    country: "United States",
  },
  {
    label: "Connecticut",
    value: "CT",
    country: "United States",
  },
  {
    label: "Delaware",
    value: "DE",
    country: "United States",
  },
  {
    label: "Florida",
    value: "FL",
    country: "United States",
  },
  {
    label: "Georgia",
    value: "GA",
    country: "United States",
  },
  {
    label: "Hawaii",
    value: "HI",
    country: "United States",
  },
  {
    label: "Idaho",
    value: "ID",
    country: "United States",
  },
  {
    label: "Illinois",
    value: "IL",
    country: "United States",
  },
  {
    label: "Indiana",
    value: "IN",
    country: "United States",
  },
  {
    label: "Iowa",
    value: "IA",
    country: "United States",
  },
  {
    label: "Kansas",
    value: "KS",
    country: "United States",
  },
  {
    label: "Kentucky",
    value: "KY",
    country: "United States",
  },
  {
    label: "Louisiana",
    value: "LA",
    country: "United States",
  },
  {
    label: "Maine",
    value: "ME",
    country: "United States",
  },
  {
    label: "Maryland",
    value: "MD",
    country: "United States",
  },
  {
    label: "Massachusetts",
    value: "MA",
    country: "United States",
  },
  {
    label: "Michigan",
    value: "MI",
    country: "United States",
  },
  {
    label: "Minnesota",
    value: "MN",
    country: "United States",
  },
  {
    label: "Mississippi",
    value: "MS",
    country: "United States",
  },
  {
    label: "Missouri",
    value: "MO",
    country: "United States",
  },
  {
    label: "Montana",
    value: "MT",
    country: "United States",
  },
  {
    label: "Nebraska",
    value: "NE",
    country: "United States",
  },
  {
    label: "Nevada",
    value: "NV",
    country: "United States",
  },
  {
    label: "New Hampshire",
    value: "NH",
    country: "United States",
  },
  {
    label: "New Jersey",
    value: "NJ",
    country: "United States",
  },
  {
    label: "New Mexico",
    value: "NM",
    country: "United States",
  },
  {
    label: "New York",
    value: "NY",
    country: "United States",
  },
  {
    label: "North Carolina",
    value: "NC",
    country: "United States",
  },
  {
    label: "North Dakota",
    value: "ND",
    country: "United States",
  },
  {
    label: "Ohio",
    value: "OH",
    country: "United States",
  },
  {
    label: "Oklahoma",
    value: "OK",
    country: "United States",
  },
  {
    label: "Oregon",
    value: "OR",
    country: "United States",
  },
  {
    label: "Pennsylvania",
    value: "PA",
    country: "United States",
  },
  {
    label: "Rhode Island",
    value: "RI",
    country: "United States",
  },
  {
    label: "South Carolina",
    value: "SC",
    country: "United States",
  },
  {
    label: "South Dakota",
    value: "SD",
    country: "United States",
  },
  {
    label: "Tennessee",
    value: "TN",
    country: "United States",
  },
  {
    label: "Texas",
    value: "TX",
    country: "United States",
  },
  {
    label: "Utah",
    value: "UT",
    country: "United States",
  },
  {
    label: "Vermont",
    value: "VT",
    country: "United States",
  },
  {
    label: "Virginia",
    value: "VA",
    country: "United States",
  },
  {
    label: "Washington",
    value: "WA",
    country: "United States",
  },
  {
    label: "West Virginia",
    value: "WV",
    country: "United States",
  },
  {
    label: "Wisconsin",
    value: "WI",
    country: "United States",
  },
  {
    label: "Wyoming",
    value: "WY",
    country: "United States",
  },
  {
    label: "Guam",
    value: "Guam",
    country: "United States",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
    country: "United States",
  },
  {
    label: "U.S. Virgin Islands",
    value: "U.S. Virgin Islands",
    country: "United States",
  },
  {
    label: "New South Wales",
    value: "New South Wales",
    country: "Australia",
  },
  {
    label: "Australian Capital Territory",
    value: "Australian Capital Territory",
    country: "Australia",
  },
  {
    label: "Queensland",
    value: "Queensland",
    country: "Australia",
  },
  {
    label: "South Australia",
    value: "South Australia",
    country: "Australia",
  },
  {
    label: "Tasmania",
    value: "Tasmania",
    country: "Australia",
  },
  {
    label: "Victoria",
    value: "Victoria",
    country: "Australia",
  },
  {
    label: "Western Australia",
    value: "Western Australia",
    country: "Australia",
  },
  {
    label: "Alberta",
    value: "AB",
    country: "Canada",
  },
  {
    label: "British Columbia",
    value: "BC",
    country: "Canada",
  },
  {
    label: "Manitoba",
    value: "MB",
    country: "Canada",
  },
  {
    label: "New Brunswick",
    value: "NB",
    country: "Canada",
  },
  {
    label: "Newfoundland and Labrador",
    value: "NL",
    country: "Canada",
  },
  {
    label: "Northwest Territories",
    value: "NT",
    country: "Canada",
  },
  {
    label: "Nova Scotia",
    value: "NS",
    country: "Canada",
  },
  {
    label: "Nunavut",
    value: "Nunavut",
    country: "Canada",
  },
  {
    label: "Ontario",
    value: "ON",
    country: "Canada",
  },
  {
    label: "Prince Edward Island",
    value: "PE",
    country: "Canada",
  },
  {
    label: "Quebec",
    value: "QC",
    country: "Canada",
  },
  {
    label: "Saskatchewan",
    value: "SK",
    country: "Canada",
  },
  {
    label: "Yukon",
    value: "Yukon",
    country: "Canada",
  },
  {
    label: "Aberdeenshire",
    value: "Aberdeenshire",
    country: "Scotland",
  },
  {
    label: "Angus",
    value: "Angus",
    country: "Scotland",
  },
  {
    label: "Argyll & Bute",
    value: "Argyll & Bute",
    country: "Scotland",
  },
  {
    label: "Ayrshire",
    value: "Ayrshire",
    country: "Scotland",
  },
  {
    label: "Banffshire",
    value: "Banffshire",
    country: "Scotland",
  },
  {
    label: "Buteshire",
    value: "Buteshire",
    country: "Scotland",
  },
  {
    label: "Clackmannanshire",
    value: "Clackmannanshire",
    country: "Scotland",
  },
  {
    label: "Dumfries & Galloway",
    value: "Dumfries & Galloway",
    country: "Scotland",
  },
  {
    label: "Dunbartonshire",
    value: "Dunbartonshire",
    country: "Scotland",
  },
  {
    label: "Dundee",
    value: "Dundee",
    country: "Scotland",
  },
  {
    label: "East Ayrshire",
    value: "East Ayrshire",
    country: "Scotland",
  },
  {
    label: "East Dunbartonshire",
    value: "East Dunbartonshire",
    country: "Scotland",
  },
  {
    label: "East Lothian",
    value: "East Lothian",
    country: "Scotland",
  },
  {
    label: "East Renfrewshire",
    value: "East Renfrewshire",
    country: "Scotland",
  },
  {
    label: "City of Edinburgh",
    value: "City of Edinburgh",
    country: "Scotland",
  },
  {
    label: "Falkirk",
    value: "Falkirk",
    country: "Scotland",
  },
  {
    label: "Fife",
    value: "Fife",
    country: "Scotland",
  },
  {
    label: "Glasgow",
    value: "Glasgow",
    country: "Scotland",
  },
  {
    label: "Highland",
    value: "Highland",
    country: "Scotland",
  },
  {
    label: "Inverness-shire",
    value: "Inverness-shire",
    country: "Scotland",
  },
  {
    label: "Kincardineshire",
    value: "Kincardineshire",
    country: "Scotland",
  },
  {
    label: "Kirkcudbrightshire",
    value: "Kirkcudbrightshire",
    country: "Scotland",
  },
  {
    label: "Lanarkshire",
    value: "Lanarkshire",
    country: "Scotland",
  },
  {
    label: "Midlothian",
    value: "Midlothian",
    country: "Scotland",
  },
  {
    label: "Moray",
    value: "Moray",
    country: "Scotland",
  },
  {
    label: "North Ayrshire",
    value: "North Ayrshire",
    country: "Scotland",
  },
  {
    label: "North Lanarkshire",
    value: "North Lanarkshire",
    country: "Scotland",
  },
  {
    label: "Orkney Islands",
    value: "Orkney Islands",
    country: "Scotland",
  },
  {
    label: "Perth & Kinross",
    value: "Perth & Kinross",
    country: "Scotland",
  },
  {
    label: "Perthshire",
    value: "Perthshire",
    country: "Scotland",
  },
  {
    label: "Renfrewshire",
    value: "Renfrewshire",
    country: "Scotland",
  },
  {
    label: "Ross-shire",
    value: "Ross-shire",
    country: "Scotland",
  },
  {
    label: "Roxburghshire",
    value: "Roxburghshire",
    country: "Scotland",
  },
  {
    label: "Scottish Borders",
    value: "Scottish Borders",
    country: "Scotland",
  },
  {
    label: "Selkirkshire",
    value: "Selkirkshire",
    country: "Scotland",
  },
  {
    label: "South Ayrshire",
    value: "South Ayrshire",
    country: "Scotland",
  },
  {
    label: "South Lanarkshire",
    value: "South Lanarkshire",
    country: "Scotland",
  },
  {
    label: "Stirling",
    value: "Stirling",
    country: "Scotland",
  },
  {
    label: "Stirlingshire",
    value: "Stirlingshire",
    country: "Scotland",
  },
  {
    label: "Sutherland",
    value: "Sutherland",
    country: "Scotland",
  },
  {
    label: "Tweeddale",
    value: "Tweeddale",
    country: "Scotland",
  },
  {
    label: "West Dunbartonshire",
    value: "West Dunbartonshire",
    country: "Scotland",
  },
  {
    label: "West Lothian",
    value: "West Lothian",
    country: "Scotland",
  },
  {
    label: "Western Isles",
    value: "Western Isles",
    country: "Scotland",
  },
  {
    label: "Wigtownshire",
    value: "Wigtownshire",
    country: "Scotland",
  },
  {
    label: "Bedfordshire",
    value: "Bedfordshire",
    country: "England",
  },
  {
    label: "Berkshire",
    value: "Berkshire",
    country: "England",
  },
  {
    label: "Bristol",
    value: "Bristol",
    country: "England",
  },
  {
    label: "Buckinghamshire",
    value: "Buckinghamshire",
    country: "England",
  },
  {
    label: "Cambridgeshire",
    value: "Cambridgeshire",
    country: "England",
  },
  {
    label: "Cheshire",
    value: "Cheshire",
    country: "England",
  },
  {
    label: "City of London",
    value: "City of London",
    country: "England",
  },
  {
    label: "Cornwall",
    value: "Cornwall",
    country: "England",
  },
  {
    label: "Cumberland",
    value: "Cumberland",
    country: "England",
  },
  {
    label: "Cumbria",
    value: "Cumbria",
    country: "England",
  },
  {
    label: "Derbyshire",
    value: "Derbyshire",
    country: "England",
  },
  {
    label: "Devon",
    value: "Devon",
    country: "England",
  },
  {
    label: "Dorset",
    value: "Dorset",
    country: "England",
  },
  {
    label: "Co. Durham",
    value: "Co. Durham",
    country: "England",
  },
  {
    label: "East Riding of Yorkshire",
    value: "East Riding of Yorkshire",
    country: "England",
  },
  {
    label: "East Sussex",
    value: "East Sussex",
    country: "England",
  },
  {
    label: "East Yorkshire",
    value: "East Yorkshire",
    country: "England",
  },
  {
    label: "Essex",
    value: "Essex",
    country: "England",
  },
  {
    label: "Gloucestershire",
    value: "Gloucestershire",
    country: "England",
  },
  {
    label: "Greater London",
    value: "Greater London",
    country: "England",
  },
  {
    label: "Greater Manchester",
    value: "Greater Manchester",
    country: "England",
  },
  {
    label: "Hampshire",
    value: "Hampshire",
    country: "England",
  },
  {
    label: "Herefordshire",
    value: "Herefordshire",
    country: "England",
  },
  {
    label: "Hertfordshire",
    value: "Hertfordshire",
    country: "England",
  },
  {
    label: "Isle of Wight",
    value: "Isle of Wight",
    country: "England",
  },
  {
    label: "Kent",
    value: "Kent",
    country: "England",
  },
  {
    label: "Lancashire",
    value: "Lancashire",
    country: "England",
  },
  {
    label: "Leicestershire",
    value: "Leicestershire",
    country: "England",
  },
  {
    label: "Lincolnshire",
    value: "Lincolnshire",
    country: "England",
  },
  {
    label: "Merseyside",
    value: "Merseyside",
    country: "England",
  },
  {
    label: "Norfolk",
    value: "Norfolk",
    country: "England",
  },
  {
    label: "North Yorkshire",
    value: "North Yorkshire",
    country: "England",
  },
  {
    label: "Northamptonshire",
    value: "Northamptonshire",
    country: "England",
  },
  {
    label: "Northumberland",
    value: "Northumberland",
    country: "England",
  },
  {
    label: "Nottinghamshire",
    value: "Nottinghamshire",
    country: "England",
  },
  {
    label: "Oxfordshire",
    value: "Oxfordshire",
    country: "England",
  },
  {
    label: "Rutland",
    value: "Rutland",
    country: "England",
  },
  {
    label: "Shropshire",
    value: "Shropshire",
    country: "England",
  },
  {
    label: "Somerset",
    value: "Somerset",
    country: "England",
  },
  {
    label: "South Yorkshire",
    value: "South Yorkshire",
    country: "England",
  },
  {
    label: "Staffordshire",
    value: "Staffordshire",
    country: "England",
  },
  {
    label: "Suffolk",
    value: "Suffolk",
    country: "England",
  },
  {
    label: "Surrey",
    value: "Surrey",
    country: "England",
  },
  {
    label: "Tyne and Wear",
    value: "Tyne and Wear",
    country: "England",
  },
  {
    label: "Warwickshire",
    value: "Warwickshire",
    country: "England",
  },
  {
    label: "West Midlands",
    value: "West Midlands",
    country: "England",
  },
  {
    label: "West Sussex",
    value: "West Sussex",
    country: "England",
  },
  {
    label: "West Yorkshire",
    value: "West Yorkshire",
    country: "England",
  },
  {
    label: "Wiltshire",
    value: "Wiltshire",
    country: "England",
  },
  {
    label: "Worcestershire",
    value: "Worcestershire",
    country: "England",
  },
  {
    label: "Anglesey",
    value: "Anglesey",
    country: "Wales",
  },
  {
    label: "Brecknockshire",
    value: "Brecknockshire",
    country: "Wales",
  },
  {
    label: "Caernarfonshire",
    value: "Caernarfonshire",
    country: "Wales",
  },
  {
    label: "Carmarthenshire",
    value: "Carmarthenshire",
    country: "Wales",
  },
  {
    label: "Ceredigion",
    value: "Ceredigion",
    country: "Wales",
  },
  {
    label: "Conwy County",
    value: "Conwy County",
    country: "Wales",
  },
  {
    label: "Cardiganshire",
    value: "Cardiganshire",
    country: "Wales",
  },
  {
    label: "Denbighshire",
    value: "Denbighshire",
    country: "Wales",
  },
  {
    label: "Flintshire",
    value: "Flintshire",
    country: "Wales",
  },
  {
    label: "Glamorgan",
    value: "Glamorgan",
    country: "Wales",
  },
  {
    label: "Gwynedd",
    value: "Gwynedd",
    country: "Wales",
  },
  {
    label: "Merioneth",
    value: "Merioneth",
    country: "Wales",
  },
  {
    label: "Monmouthshire",
    value: "Monmouthshire",
    country: "Wales",
  },
  {
    label: "Montgomeryshire",
    value: "Montgomeryshire",
    country: "Wales",
  },
  {
    label: "North Wales",
    value: "North Wales",
    country: "Wales",
  },
  {
    label: "Pembrokeshire",
    value: "Pembrokeshire",
    country: "Wales",
  },
  {
    label: "Powys",
    value: "Powys",
    country: "Wales",
  },
  {
    label: "Radnorshire",
    value: "Radnorshire",
    country: "Wales",
  },
  {
    label: "Swansea",
    value: "Swansea",
    country: "Wales",
  },
  {
    label: "Vale of Glamorgan",
    value: "Vale of Glamorgan",
    country: "Wales",
  },
  {
    label: "Bermuda",
    value: "Bermuda",
    country: "Bermuda",
  },
  {
    label: "Auckland",
    value: "Auckland",
    country: "New Zealand",
  },
  {
    label: "Bay of Plenty",
    value: "Bay of Plenty",
    country: "New Zealand",
  },
  {
    label: "Canterbury",
    value: "Canterbury",
    country: "New Zealand",
  },
  {
    label: "Gisborne",
    value: "Gisborne",
    country: "New Zealand",
  },
  {
    label: "Hawke's Bay",
    value: "Hawke's Bay",
    country: "New Zealand",
  },
  {
    label: "Manawatu-Wanganui",
    value: "Manawatu-Wanganui",
    country: "New Zealand",
  },
  {
    label: "Marlborough",
    value: "Marlborough",
    country: "New Zealand",
  },
  {
    label: "Nelson",
    value: "Nelson",
    country: "New Zealand",
  },
  {
    label: "Northland",
    value: "Northland",
    country: "New Zealand",
  },
  {
    label: "Otago",
    value: "Otago",
    country: "New Zealand",
  },
  {
    label: "Southland",
    value: "Southland",
    country: "New Zealand",
  },
  {
    label: "Taranaki",
    value: "Taranaki",
    country: "New Zealand",
  },
  {
    label: "Tasman",
    value: "Tasman",
    country: "New Zealand",
  },
  {
    label: "Waikato",
    value: "Waikato",
    country: "New Zealand",
  },
  {
    label: "Wellington",
    value: "Wellington",
    country: "New Zealand",
  },
  {
    label: "West Coast",
    value: "West Coast",
    country: "New Zealand",
  },
  {
    label: "Co. Antrim",
    value: "Co. Antrim",
    country: "Northern Ireland",
  },
  {
    label: "Co. Armagh",
    value: "Co. Armagh",
    country: "Northern Ireland",
  },
  {
    label: "Co. Down",
    value: "Co. Down",
    country: "Northern Ireland",
  },
  {
    label: "Co. Fermanagh",
    value: "Co. Fermanagh",
    country: "Northern Ireland",
  },
  {
    label: "Co. Londonderry",
    value: "Co. Londonderry",
    country: "Northern Ireland",
  },
  {
    label: "Co. Tyrone",
    value: "Co. Tyrone",
    country: "Northern Ireland",
  },
  {
    label: "Co. Carlow",
    value: "Co. Carlow",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Cavan",
    value: "Co. Cavan",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Clare",
    value: "Co. Clare",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Cork",
    value: "Co. Cork",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Donegal",
    value: "Co. Donegal",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Dublin",
    value: "Co. Dublin",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Galway",
    value: "Co. Galway",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Kerry",
    value: "Co. Kerry",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Kildare",
    value: "Co. Kildare",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Kilkenny",
    value: "Co. Kilkenny",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Laois",
    value: "Co. Laois",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Leitrim",
    value: "Co. Leitrim",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Limerick",
    value: "Co. Limerick",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Longford",
    value: "Co. Longford",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Louth",
    value: "Co. Louth",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Mayo",
    value: "Co. Mayo",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Meath",
    value: "Co. Meath",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Monaghan",
    value: "Co. Monaghan",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Offaly",
    value: "Co. Offaly",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Roscommon",
    value: "Co. Roscommon",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Sligo",
    value: "Co. Sligo",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Tipperary",
    value: "Co. Tipperary",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Waterford",
    value: "Co. Waterford",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Westmeath",
    value: "Co. Westmeath",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Wexford",
    value: "Co. Wexford",
    country: "Republic of Ireland",
  },
  {
    label: "Co. Wicklow",
    value: "Co. Wicklow",
    country: "Republic of Ireland",
  },
  {
    label: "Grand Bahama Island",
    value: "Grand Bahama Island",
    country: "Bahamas, The",
  },
  {
    label: "Guernsey",
    value: "Guernsey",
    country: "United Kingdom",
  },
  {
    label: "Jersey",
    value: "Jersey",
    country: "United Kingdom",
  },
  {
    label: "Isle of Man",
    value: "Isle of Man",
    country: "United Kingdom",
  },
];

export default states;
