import React from "react";
import { Flex, Text } from "components";
import { LegendSquare } from "./Chart.styled";

export default ({ legend }) => (
  <Flex justify="space-around" width="100%" flexWrap="wrap" margin="24px 0 0">
    {legend?.map(({ label, color }) => (
      <Flex key={label}>
        <LegendSquare {...{ color }} />
        <Text padding="0 0 0 5px">{label}</Text>
      </Flex>
    ))}
  </Flex>
);
