

import React from "react";
import {
  Flex,
  Text,
} from "components";
import { ResponsiveLine } from "@nivo/line";

const AnalyticsCard = ({title, total, preNeed, atNeed, chartData, screenWidth}) => (
  <Flex
    background="white"
    borderRadius="8px"
    padding="16px"
    column
    align="flex-start"
    width="fit-content"
  >
  <Text kind="b" margin="0 0 8px 0">
    {title}
  </Text>
  <Flex>
    <Text kind="f2 b">
      {total}
    </Text>
    <Flex
      margin="0 0 0 16px"
      column
      justify="space-between"
      align="flex-start"
    >
      <Text kind="f8 b">
        {preNeed}
        &nbsp;&nbsp;PRE-NEED
      </Text>
      <Text kind="f8 b">
        {atNeed}
        &nbsp;&nbsp;AT-NEED
      </Text>
    </Flex>
  </Flex>
  {/* 64 is the amount of x-axis padding */}
  <div style={{ height: 120, width: screenWidth - 64, maxWidth: 500 }}>
    <ResponsiveLine
      margin={{ top: 20, right: 0, bottom: 0, left: 0 }}
      curve="monotoneX"
      axisTop={null}
      axisRight={null}
      axisLeft={{
          orient: "left",
          legend: "day hour",
          legendOffset: -80,
          legendPosition: "middle"
      }}
      enableGridX={false}
      enableGridY={false}
      enablePoints={false}
      enableArea={true}
      data={[
        {
          id: "total by year",
          data: chartData,
        },
      ]}
    />
  </div>
</Flex>
)

export default AnalyticsCard;