const setDay = (month) => {
  let monthAsInteger;
  let lastDayAsInteger;
  switch (month) {
    case "January":
      monthAsInteger = "01";
      lastDayAsInteger = "31";
      break;
    case "February":
      // This should probably have an edge case for leap years, but this is just my first pass.
      monthAsInteger = "02";
      lastDayAsInteger = "28";
      break;
    case "March":
      monthAsInteger = "03";
      lastDayAsInteger = "31";
      break;
    case "April":
      monthAsInteger = "04";
      lastDayAsInteger = "30";
      break;
    case "May":
      monthAsInteger = "05";
      lastDayAsInteger = "31";
      break;
    case "June":
      monthAsInteger = "06";
      lastDayAsInteger = "30";
      break;
    case "July":
      monthAsInteger = "07";
      lastDayAsInteger = "31";
      break;
    case "August":
      monthAsInteger = "08";
      lastDayAsInteger = "31";
      break;
    case "September":
      monthAsInteger = "09";
      lastDayAsInteger = "30";
      break;
    case "October":
      monthAsInteger = "10";
      lastDayAsInteger = "31";
      break;
    case "November":
      monthAsInteger = "11";
      lastDayAsInteger = "30";
      break;
    case "December":
      monthAsInteger = "12";
      lastDayAsInteger = "31";
      break;
    default:
      monthAsInteger = "07";
      lastDayAsInteger = "31";
  }

  return { monthAsInteger, lastDayAsInteger };
};

export default ({ activeMonth, activeYear }) => {
  const { monthAsInteger, lastDayAsInteger } = setDay(activeMonth);

  return {
    startOfMonth: `${activeYear}-${monthAsInteger}-01T00:00:00-0700`,
    endOfMonth: `${activeYear}-${monthAsInteger}-${lastDayAsInteger}T00:00:00-0700`,
  };
};
