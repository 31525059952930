const timestampFormatter = (timestamp, format = "full") => {
  let date = new Date(timestamp);
  let hh = date.getHours();
  let mm = date.getMinutes();
  let ss = date.getSeconds();
  let ampm = "am";

  if (hh > 12) {
    hh -= 12;
    ampm = "pm";
  }

  if (hh.length === 2 && hh !== "00" && hh < 10) {
    hh = hh.replace(0, "");
  }

  if (hh === "00") {
    hh = 12;
  }

  let yy = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  let mo = new Intl.DateTimeFormat("en", { month: "long" }).format(date);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);

  if (format === "full") {
    return `${mo} ${da}, ${yy} at ${hh}:${mm}:${ss} ${ampm}`;
  }

  if (format === "date") {
    return `${mo} ${da}, ${yy}`;
  }

  if (format === "time") {
    return `${hh}:${mm}:${ss} ${ampm}`;
  }

  if (format === "month") {
    return `${mo}`;
  }

  if (format === "year") {
    return `${yy}`;
  }
};

export default timestampFormatter;
