import React from "react";
import * as Styled from "./TableDrawer.styled";
import { Flex, Text, Button } from "components";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import { navigate } from "@reach/router";
import { colors } from "utils";

const TableDrawer = ({
  items,
  title,
  children,
  openOnPage,
  pagePath,
  pathLabel,
  funeralHome,
}) => (
  <Styled.Drawer>
    <Flex justify={funeralHome ? "flex-end" : "space-between"}>
      {title && (
        <Text kind="f4 b" color="BASE" margin="12px 0 18px">
          {title}
        </Text>
      )}
      {openOnPage && (
        <Button kind="primary" padding="2px" onClick={() => navigate(pagePath)}>
          <Text
            kind="f65 b"
            line="1rem"
            margin="0 4px 4px"
            display="inline-block"
          >
            {pathLabel}
          </Text>
          <BsBoxArrowInUpRight
            style={{
              fontSize: "1.5rem",
              color: colors.BASE,
              height: "100%",
              verticalAlign: "middle",
              margin: "0 4px 0",
            }}
          />
        </Button>
      )}
    </Flex>
    {items && (
      <Flex width="100%" flexWrap="wrap">
        {items.map(({ label, value }) => (
          <Styled.DrawerItem key={label}>
            <Text kind="f5 b">{label}</Text>
            <Text kind="f5">{value}</Text>
          </Styled.DrawerItem>
        ))}
      </Flex>
    )}
    {children}
  </Styled.Drawer>
);

export default TableDrawer;
