import React, { useState, useEffect, useRef } from "react";
import { Button, Flex, Text } from "components";
import styled from "styled-components";
import Papa from "papaparse";
import { useToken } from "providers/AuthProvider";
import { AiOutlineCloudUpload } from "react-icons/ai";

const Wrap = styled.div`
  .file-drop-target {
    transition-duration: 0.3s;

    &.file-drop-dragging-over-frame {
      opacity: 0.6;
    }
  }
`;

const HiddenInput = styled.input`
  overflow: hidden;
  width: 0;
  height: 0;
`;

const Main = ({ setInvalidations, setResponse, label, postUrl, validate }) => {
  const [loading, setLoading] = useState(false);
  const [token] = useToken();
  const inputRef = useRef();

  async function change(e) {
    setLoading(true);
    setResponse(null);
    setInvalidations(null);
    try {
      const { files } = e.target;
      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: "greedy",
        complete: async ({ data }) => {
          // Runs test on JSON data to confirm it is formatted correctly

          const { invalidations, validated } = validate(data);

          if (!validated) {
            setInvalidations(invalidations);
            setLoading(false);
            return;
          }

          try {
            const res = await postUrl(validated, token);
            res.detail
              ? setInvalidations([`Database Error: ${res.detail}`])
              : setResponse(res);
          } catch (e) {
            setInvalidations([`Error: ${e.message || e}`]);
          } finally {
            // Clear the current file from the input so that it can be
            // reuploaded if an error was found
            if (inputRef.current) inputRef.current.value = null;
            setLoading(false);
          }
        },
      });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  function clickFile() {
    inputRef.current.click();
  }

  useEffect(() => {
    window.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );
  }, []);

  return (
    <Wrap>
      <Button
        disabled={loading}
        margin="0 0 0 64px"
        className="blue"
        onClick={clickFile}
      >
        <Flex>
          <AiOutlineCloudUpload style={{ fontSize: "8rem" }} />
          <Text kind="f7 b" padding="0 0 0 16px">
            {loading ? "Uploading..." : label}
          </Text>
        </Flex>
      </Button>
      <HiddenInput type="file" accept=".csv" ref={inputRef} onChange={change} />
    </Wrap>
  );
};

export default Main;
