import { css } from "styled-components";

export const sizes = {
  phoneSmall: 320,
  phone: 375,
  phonePlus: 428,
  tabletSmall: 520,
  tablet: 768,
  tabletMedium: 800,
  tabletPlus: 960,
  laptop: 1024,
  laptopPlus: 1150,
  desktop: 1230,
  desktopPlus: 1300,
};

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media ${label === "laptop" && "screen and "} (min-width: ${sizes[
        label
      ]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
