import styled from "styled-components";
import { Flex, Text, Button } from "components";
import { colors, media } from "utils";
import check from "../../static/checkmark.png";

export const Filter = styled(Flex)`
  flex-direction: column;
  height: 100%;

  margin: 0 20px 0 0;
  padding: 1rem;

  border-radius: 4px;
  background-color: ${colors.WHITE};
  box-shadow: ${({ shadow = "0 4px 4px -2px rgba(0, 20, 30, 0.2)" }) => shadow};

  ${media.tabletPlus`
    min-width: 300px
  `}
`;

export const FilterTitle = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  padding: 8px 0;
  text-align: center;
  width: 100%;
  &.first {
    border-top: none;
  }
`;

export const Radio = styled.label`
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  span.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: 50%;
  }

  &:hover input ~ span.checkmark {
    background-color: #ccc;
  }

  & input:checked ~ span.checkmark {
    background-color: ${colors.GOLD};
  }

  span.checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  & input:checked ~ span.checkmark:after {
    display: block;
  }

  & span.checkmark:after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${colors.WHITE};
  }
`;

export const Checkbox = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  &.center {
    justify-content: center;
  }

  & > label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 0.25em;
  }

  & > input {
    visibility: hidden;
    position: absolute;
  }

  & > img {
    display: inline-block;
    padding: 0px;
    min-height: 1em;
    min-width: 1em;
    background: none;
  }

  & > input:checked + img {
    background: url(${check});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 0.75em 0.7em;
  }
`;

export const ParentClear = styled(Button)`
  position: absolute;
  right: 0;
  font-size: 16px;
  padding: 0;
  margin: 0 24px;
  background: transparent;
  box-shadow: none;
  text-decoration: underline;
  &:hover {
    color: ${colors.GOLD};
  }
`;
