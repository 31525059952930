import React from "react";
import { Tooltip, TooltipBackground } from "./Chart.styled";
import { Flex, Text } from "..";

export default ({ date, label, value }) => (
  <Tooltip>
    <TooltipBackground>
      <Flex column width="100%">
        <Text kind="f9 b" color={"NEUTRAL_75"}>
          {label}
        </Text>
        <Text kind="f9" color={"NEUTRAL_75"}>
          {date}
        </Text>
        <Text padding="8px 0 0" kind="f7 b">
          {value}
        </Text>
      </Flex>
    </TooltipBackground>
  </Tooltip>
);
