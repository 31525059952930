import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@nivo/core";
import * as Styled from "./Chart.styled";
import { Flex, Text } from "..";

const numberToMonthString = (value) => {
  let str = "";
  switch (value) {
    case 1:
      str = "Jan";
      break;
    case 2:
      str = "Feb";
      break;
    case 3:
      str = "Mar";
      break;
    case 4:
      str = "Apr";
      break;
    case 5:
      str = "May";
      break;
    case 6:
      str = "Jun";
      break;
    case 7:
      str = "Jul";
      break;
    case 8:
      str = "Aug";
      break;
    case 9:
      str = "Sep";
      break;
    case 10:
      str = "Oct";
      break;
    case 11:
      str = "Nov";
      break;
    case 12:
      str = "Dec";
      break;
    default:
      break;
  }
  return str;
};

const BAR_WIDTH = 16;
const BAR_PADDING = 4;

const Bar = ({ keys, data, indexBy, colors, legend }) => {
  if (!data) return null;

  const range = (start, stop, step) => {
    return Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  };

  const chartWidth = data.length * (BAR_WIDTH + BAR_PADDING);
  const tallestBar = Math.max(
    ...data.flatMap(
      ({ free_obit_count, paid_obit_count }) =>
        free_obit_count + paid_obit_count
    )
  );
  const yAxisGroupings = range(
    0,
    tallestBar,
    Math.round(Math.round(tallestBar / 6) / 10) * 10 || 5
  );
  // FOR ADDING 10 SO ZERO DATA SHOWS UP
  // const newdata = data.map(({ free_obit_count, paid_obit_count, ...rest }) => ({
  //   ...rest,
  //   paid_obit_count: paid_obit_count + 10,
  //   free_obit_count: free_obit_count + 10,
  // }));

  const CustomYTick = (tick) => {
    const theme = useTheme();
    if (!yAxisGroupings.includes(tick.value)) return null;
    return (
      <g transform={`translate(${tick.x},${tick.y + 2})`}>
        <rect
          x={-12}
          y={-12}
          rx={2}
          ry={2}
          width={24}
          height={24}
          fill="#fff"
        />
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
            fill: "#7F898E",
            fontSize: 14,
          }}
        >
          {tick.value}
        </text>
      </g>
    );
  };

  const CustomXTick = (tick) => {
    const theme = useTheme();

    return (
      <g transform={`translate(${tick.x},${tick.y + 24})`}>
        <rect
          x={-12}
          y={-12}
          rx={2}
          ry={2}
          width={24}
          height={24}
          fill="#fff"
        />
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
            fill: "#7F898E",
            fontSize: 14,
          }}
        >
          {numberToMonthString(tick.value)}
        </text>
      </g>
    );
  };

  return (
    <Flex align={"flex-end"} width="100%">
      <div style={{ width: `${chartWidth + 190}px` }}>
        <Styled.HeightProvider height="300px">
          {" "}
          {/** 300px */}
          <ResponsiveBar
            data={data}
            groupMode="stacked"
            indexBy={indexBy}
            colors={colors}
            keys={keys}
            padding={0.6}
            margin={{ top: 10, right: 0, bottom: 50, left: 60 }}
            enableGridX={false}
            enableGridY={true}
            gridYValues={yAxisGroupings} //Bring back
            enableLabel={false}
            layout="vertical"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 30,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: 32,
              renderTick: CustomXTick,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              renderTick: CustomYTick,
            }}
            theme={{
              tooltip: {
                container: {
                  background: "transparent",
                  boxShadow: "0",
                },
              },
            }}
          />
        </Styled.HeightProvider>
        <Flex justify="space-around">
          {legend?.map(({ label, color }) => (
            <Flex key={label}>
              <Styled.LegendSquare {...{ color }} />
              <Text padding="0 0 0 5px">{label}</Text>
            </Flex>
          ))}
        </Flex>
      </div>
    </Flex>
  );
};

Bar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.array,
};

export default Bar;
