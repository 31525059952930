const getToken = () => {
  const parsedToken = JSON.parse(
    localStorage.getItem(
      `@@auth0res@@::${process.env.REACT_APP_AUTH0_CLIENTID}::${process.env.REACT_APP_AUTH0_AUDIENCE}`
    )
  );
  return parsedToken;
};

export default getToken;
