import React from "react";
import Bar from "./Bar";
import BarStacked from "./BarStacked";
import BarStackedDaily from "./BarStackedDaily";
import Line from "./Line";
import Donut from "./Donut";
import { sortByDate } from "utils";
import BarStackedMonthly from "components/Chart/BarStackedMonthly";

const donutSort = ({ fh_display, market_share }) => {
  return [
    {
      id: "fh_display",
      label: fh_display,
      value: Number(market_share),
      color: "hsl(196, 70%, 50%)",
    },
    {
      id: "competitors",
      label: "competitors",
      value: 100 - Number(market_share),
      color: "hsl(80, 70%, 50%)",
    },
  ];
};

const Chart = ({ data, chartStyle, ...rest }) => {
  const sortedData = chartStyle !== "donut" && sortByDate(data);
  return (
    <>
      {chartStyle === "bar" && <Bar data={sortedData} {...rest} />}
      {chartStyle === "barStacked" && (
        <BarStacked data={sortedData} {...rest} />
      )}
      {chartStyle === "barStackedDaily" && (
        <BarStackedDaily data={sortedData} {...rest} />
      )}
      {chartStyle === "barStackedMonthly" && (
        <BarStackedMonthly data={sortedData} {...rest} />
      )}
      {chartStyle === "line" && <Line data={sortedData} {...rest} />}
      {chartStyle === "donut" && <Donut data={donutSort(data)} {...rest} />}
    </>
  );
};

export default Chart;
