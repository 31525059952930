import React from "react";
import styled from "styled-components";
import { colors } from "utils";
import { Text } from "components";

export const Title = styled((props) => <Text {...props} />)`
  position: relative;
  &:after {
    position: absolute;
    top: 1.75em;
    content: "\\00ae";
    color: ${colors.WHITE};
    font-size: 0.2em;
    font-family: "Open Sans", sans-serif;
  }
`;

export const Pro = styled.div`
  background-color: ${colors.GOLD_100};
  color: ${colors.WHITE};
  padding: 4px;
  margin-left: 12px;
  font-weight: bold;
  font-size: ${({ logoSize = "16px" }) => logoSize};
`;
