import styled from "styled-components";
import { Flex } from "components";
import { colors } from "utils";

// default  dropdown styling and logic
export const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
`;

export const ClickHandler = styled(Flex)`
  cursor: pointer;
  justify-content: center;
  background: ${colors.WHITE};
  border: 1px solid ${colors.NEUTRAL_20};
  border-radius: 2px;
  padding: 8px 12px;
  width: 100%;
  &:hover {
    background: ${colors.BASE};
  }
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  background: ${colors.WHITE};
  border: 1px solid ${colors.NEUTRAL_05};
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 9999;
  cursor: pointer;
`;

export const Link = styled.div`
  color: black;
  cursor: pointer;
  width: 100%;
  padding: 8px 0 8px 4px;
  &:hover {
    background: ${colors.BLUE_100};
    color: ${colors.WHITE};
  }
`;

// expanded dropdown styling and logic
export const SelectStyled = styled.select`
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  width: ${({ width = "100%" }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  background: ${({ background }) => (background ? background : colors.BASE)};
  border: ${({ border }) => (border ? border : "none")};
  font-size: ${({ size }) => size};
  text-overflow: ${({ textOverflow = "ellipsis" }) => textOverflow};
  white-space: ${({ whiteSpace = "nowrap" }) => whiteSpace};
  overflow: ${({ overflow = "hidden" }) => overflow};
  overflow-y: ${({ scrollY }) => scrollY && "scroll"};
  overflow-x: ${({ scrollX }) => scrollX && "scroll"};
  min-height: ${({ minHeight }) => minHeight};

  &.default {
    padding: ${({ padding = "6px" }) => padding};
    border: none;
    width: ${({ width = "300px" }) => width};
    color: black;
    font-size: ${({ size = "16px" }) => size};
    line-height: 24px;
    font-weight: normal;
    border-bottom: 2px solid ${colors.NEUTRAL_50};
    &:focus {
      outline: none;
    }
  }
`;
