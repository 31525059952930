import styled from "styled-components";
import { Flex } from "components";
import { colors } from "utils";

export const Wrapper = styled.div`
  background: ${colors.WHITE};
  width: calc(100% - 256px);
  position: fixed;
  right: 0;
  padding-right: 28px;
  height: 88px;
  z-index: 999;
`;

export const Logo = styled.div`
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  background-color: ${colors.BLUE_100};
  margin-right: 12px;
`;

export const ClickHandler = styled(Flex)`
  cursor: pointer;
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  background: ${colors.WHITE};
  border: 1px solid ${colors.NEUTRAL_05};
  width: 192px;
  top: 88px;
  right: 0px;
  z-index: 9999;
  cursor: pointer;
`;

export const Link = styled.div`
  color: ${colors.BLACK};
  cursor: pointer;
  width: 100%;
  padding: 8px 0 8px 4px;
  &:hover {
    background: ${colors.BLUE_100};
    color: ${colors.WHITE};
  }
`;
