import styled from "styled-components";
import { colors } from "utils";

export const Input = styled.input`
  height: 32px;
  width: ${({ width }) => width};
  min-width: ${({ width, minWidth = width ? null : "120px" }) => minWidth};
  max-width: ${({ width, maxWidth = width ? null : "190px" }) => maxWidth};
  margin: ${({ margin = "8px 4px" }) => margin};
  border: none;
  background: ${colors.NEUTRAL_04};
  border-radius: ${({ borderRadius = "5px" }) => borderRadius};
  &::placeholder {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 300;
    color: ${colors.NEUTRAL_40};
    padding-left: ${({ paddingLeft = "12px" }) => paddingLeft};
  }
`;
