import React, { useState } from "react";
import * as Styled from "./Dropdown.styled";
import { Text } from "components";
import PropTypes from "prop-types";

const Dropdown = ({
  header,
  menuItems,
  onClick,
  width,

  // item {value & optionName key}
  expanded,
  children,
  options,
  name,
  defaultValue,
  defaultOption,
  type = "default",
  register,
  height,
  padding,
  margin,
  ...selectProps
}) => {
  const [open, setOpen] = useState(false);

  const toggle = (value) => {
    onClick(value);
    setOpen(!open);
  };

  if (!expanded) {
    return (
      <Styled.Wrapper width={width} margin={margin}>
        <Styled.ClickHandler onClick={() => setOpen(!open)}>
          {header}
        </Styled.ClickHandler>

        {open && (
          <Styled.DropDown>
            {menuItems.map((item) => (
              <Styled.Link key={item} onClick={() => toggle(item)}>
                <Text kind="f8 b">{item}</Text>
              </Styled.Link>
            ))}
          </Styled.DropDown>
        )}
      </Styled.Wrapper>
    );
  } else {
    return (
      <Styled.SelectStyled
        name={name}
        defaultValue={defaultValue || ""}
        className={type}
        ref={register}
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        {...selectProps}
      >
        {defaultOption && (
          <option value="" defaultChecked disabled>
            {defaultOption}
          </option>
        )}
        {options &&
          options.map(({ value, optionName }) => (
            <option key={value} value={value}>
              {optionName}
            </option>
          ))}
        {children}
      </Styled.SelectStyled>
    );
  }
};

Dropdown.propTypes = {
  children: PropTypes.any,
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  defaultOption: PropTypes.string,
  type: PropTypes.string,
  register: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
};

export default Dropdown;
