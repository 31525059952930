import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { colors } from "utils";

const Donut = ({ data }) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ResponsivePie
        data={data}
        margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
        innerRadius={0.75}
        colors={[colors.GOLD, colors.BASE]}
        borderWidth={1}
        borderColor={colors.BASE}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextXOffset={6}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsLinkOffset={0}
        arcLinkLabelsLinkDiagonalLength={16}
        arcLinkLabelsLinkHorizontalLength={24}
        arcLinkLabelsLinkStrokeWidth={1}
        arcLinkLabelsLinkColor={{ from: "color" }}
        enableSliceLabels={false}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

export default Donut;
