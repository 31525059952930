import { useEffect, useState } from "react";

export const useChartWidth = ({ dataLength, margin, cardWidth = 0 }) => {
  const [width, setWidth] = useState();
  const BAR_PADDING = 2;
  const barWidth =
    cardWidth > 600
      ? (cardWidth - (margin + 48) - dataLength * BAR_PADDING) / dataLength // was 8
      : 8;

  useEffect(() => {
    setWidth(dataLength * (barWidth + BAR_PADDING) + margin);
  }, [dataLength, margin, barWidth, cardWidth]);

  return width;
};

export const isMultiMonth = (data) => {
  const months = data
    .map((item) => item.utc || item.date || item.order_date)
    .map((date) => date?.substring(5, 7));

  return new Set(months).size > 1;
};
