import React from "react";
import PropTypes from "prop-types";
import { Input } from "./InputRow.styled";

const InputRow = ({ type, register, ...props }) => {
  return <Input ref={register} type={type} {...props} />;
};

Input.defaultProps = {
  type: "text",
};

Input.propTypes = {
  type: PropTypes.string,
  props: PropTypes.any,
  register: PropTypes.any,
};

export default InputRow;
