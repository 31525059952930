/***************************************************************************
* Function to perform search on any string value given an array of objects 
* ARGUEMENTS:   arr - the array to search from
                term - the search term to use for the search
                type - configures the return type: 'arr' (default) || obj 
                constrain - key in the object being mapped, when present this only compares the term against the value of this key
* RETURNS:  type = “array” - array of filtered results that satisfies the search term
            type = “object” - first found match that that satisfies the search term
****************************************************************************/
const search = (arr, term, type = "array", constrain = null) => {
  switch (type) {
    case "object":
      return arr?.find((item) => {
        if (
          Object.values(item)
            .filter((filtered) => {
              if (constrain) {
                return item[constrain] === filtered;
              } else {
                return filtered;
              }
            })
            .find((value) => {
              if (typeof value === "string") {
                return value.toLowerCase() === term.toLowerCase();
              } else {
                return value === term;
              }
            })
        ) {
          return item;
        } else {
          return null;
        }
      });
    case "array":
    default:
      return arr?.filter((item) => {
        if (
          Object.values(item)
            .filter((filtered) => {
              if (constrain) {
                return item[constrain] === filtered;
              } else {
                return filtered;
              }
            })
            .find((value) => {
              if (typeof value === "string") {
                return value.toLowerCase().includes(term.toLowerCase());
              } else {
                return value === term;
              }
            })
        ) {
          return item;
        } else {
          return null;
        }
      });
  }
};

export default search;
