import React, { useEffect, useRef, useState } from "react";
import * as Styled from "./Card.styled";
import { Chart, Flex, Text } from "components";

const Card = ({
  label,
  value,
  valueLabel,
  chartConfig,
  category,
  filterType,
  toggleFilterModal,
  kind,
  children,
  trend,
  icon,
  contentAlign = "center",
}) => {
  const id = `${label}-${category}`;
  const [cardWidth, setCardWidth] = useState(null);
  const cardRef = useRef(null);

  useEffect(() => {
    if (kind === "responsiveChart" && cardRef?.current?.clientWidth) {
      setCardWidth(cardRef.current.clientWidth);
    }
    // eslint-disable-next-line
  }, [kind, cardRef?.current?.clientWidth]);

  return (
    <Styled.Card id={id} className={kind} ref={cardRef}>
      <Flex align={contentAlign} height="100%" column>
        <Text color="NEUTRAL_75" kind="f8">
          {kind === "petit" ? label : <strong>{label}</strong>}
        </Text>
        <Flex>
          {icon && (
              <Text kind="f5" color="BLUE" display="flex" margin="0 10px 0 0">
                {icon}
              </Text>
            )
          }
          {value && value > 0 ? (
            <Styled.Value kind={kind === "petit" ? "f35 b" : "f3 b"}>
              {Number(value).toLocaleString()}
            </Styled.Value>
          ) : (
            <Styled.Value kind={kind === "petit" ? "f35 b" : "f3 b"}>
              {value ? value : 0}
            </Styled.Value>
          )}
        </Flex>
        <Flex row align="flex-start">
          {valueLabel && (
            <Text
              kind="f8 b"
              margin="0 10px 16px 0"
              color={
                isNaN(trend) || parseFloat(trend) === 0
                  ? "NEUTRAL"
                  : parseFloat(trend) > 0
                  ? "SUCCESS"
                  : "ERROR"
              }
            >
              {valueLabel || ""}
            </Text>
          )}
          {trend && !isNaN(trend) && parseFloat(trend) !== 0 && (
            <Styled.TrendArrow
              className={
                parseFloat(trend) === 0
                  ? "noChange"
                  : parseFloat(trend) > 0
                  ? "up"
                  : "down"
              }
            >
              ▾
            </Styled.TrendArrow>
          )}
        </Flex>

        {chartConfig && <Chart {...chartConfig} cardWidth={cardWidth} />}
      </Flex>
      {filterType && (
        <Styled.FilterIcon
          onClick={() => toggleFilterModal(filterType)}
          src={require("assets/icons/filterIcon.svg")}
        />
      )}
      {/* <Styled.GlossaryIcon onClick={() => toggleModal(id)}>
        i
      </Styled.GlossaryIcon>
      {open && (
        <Styled.Modal>
          <Text kind="f9" padding="0 0 12px">
            {glossary}
          </Text>
          <Flex justify="center">
            <Button kind="secondary" onClick={() => toggleModal()}>
              Dismiss
            </Button>
          </Flex>
        </Styled.Modal>
      )} */}
      {children}
    </Styled.Card>
  );
};

export default Card;
