import { del, get, patch, post } from "./request";

export const getAllFuneralHomes = async (token, params = "") => {
  return get(`admin-funeral-homes${params}`, token);
};

export const getHomesCountByState = async (groupBy, token) => {
  return get(`count-funeral-homes?groupBy=${groupBy}`, token);
};

export const getMarketshareForParentId = async (id, token) => {
  return get(`fh-parent-competitors/${id}`, token);
};

export const getAllHomesForParentId = async (id, token) => {
  return get(`funeral-homes/${id}`, token);
};

export const getHomeByUUID = async (uuid, token) => {
  return get(`funeral-home/${uuid}`, token);
};

export const getFlowersById = async (id, token) => {
  return get(`legacy-flowers/${id}`, token);
};

export const getAllObituaries = async (id, token, isParent = false) => {
  return get(`obituaries/${id}${isParent ? "?isParent=1" : ""}`, token);
};

export const getObituariesByDateOfDeath = async (
  id,
  token,
  dod_start,
  dod_end,
  isParent = false
) => {
  return get(
    `funeral_homes/${id}/obituaries?dod_start=${dod_start}&dod_end=${dod_end}${
      isParent ? "&isParent=1" : ""
    }`,
    token
  );
};

export const getObitVolumeById = async (id, token) => {
  return get(`legacy-obits/${id}`, token);
};

export const getCompetitorsById = async (id, token) => {
  return get(`fh-competitors/${id}`, token);
};

export const getLSFlowersById = async (id, token) => {
  return get(`fhls-flowers/${id}`, token);
};

export const getLSObitsById = async (id, token) => {
  return get(`fhls-obits/${id}`, token);
};

export const deleteFlowerById = async (id, token) => {
  return del(`legacy-flowers/${id}`, token);
};

export const deleteLSFlowerById = async (id, token) => {
  return del(`fhls-flowers/${id}`, token);
};

export const deleteCompetitorById = async (id, token) => {
  return del(`fh-competitor/${id}`, token);
};

export const deleteObitById = async (id, token) => {
  return del(`legacy-obits/${id}`, token);
};

export const deleteLSObitById = async (id, token) => {
  return del(`fhls-obits/${id}`, token);
};

export const getLocalAdData = async (token, body) => {
  return post("funeralHomeAdData", token, body);
};

export const getWeeklyFuneralHomeData = async (id, token) => {
  const currentDate = new Date();
  let weekAgo = new Date().setDate(currentDate.getDate() - 7);
  weekAgo = new Date(weekAgo);
  let body = {
    fh_id: id,
    start_date: weekAgo.toISOString(),
    end_date: currentDate.toISOString(),
  };
  body = JSON.stringify(body);
  const data = await post("funeralHomeData", token, body);
  const obituaries = data.traffic?.filter((data) => data.type === "obituary");
  const directories = data.traffic?.filter((data) => data.type === "directory");
  return {
    obituaries: obituaries,
    directories: directories,
    clicks: data.clicks,
  };
};

export const getMonthlyFuneralHomeData = async (
  id,
  token,
  month,
  year,
  parentId
) => {
  let numericMonth;
  let lastDay;
  switch (month) {
    case "January":
      numericMonth = "01";
      lastDay = "31";
      break;
    case "February":
      // This should probably have an edge case for leap years, but this is just my first pass.
      numericMonth = "02";
      lastDay = "28";
      break;
    case "March":
      numericMonth = "03";
      lastDay = "31";
      break;
    case "April":
      numericMonth = "04";
      lastDay = "30";
      break;
    case "May":
      numericMonth = "05";
      lastDay = "31";
      break;
    case "June":
      numericMonth = "06";
      lastDay = "30";
      break;
    case "July":
      numericMonth = "07";
      lastDay = "31";
      break;
    case "August":
      numericMonth = "08";
      lastDay = "31";
      break;
    case "September":
      numericMonth = "09";
      lastDay = "30";
      break;
    case "October":
      numericMonth = "10";
      lastDay = "31";
      break;
    case "November":
      numericMonth = "11";
      lastDay = "30";
      break;
    case "December":
      numericMonth = "12";
      lastDay = "31";
      break;
    default:
      numericMonth = "07";
      lastDay = "31";
  }
  const startOfMonth = `${year}-${numericMonth}-01T00:00:00-0700`;
  const endOfMonth = `${year}-${numericMonth}-${lastDay}T00:00:00-0700`;
  let body = {
    fh_id: id,
    start_date: startOfMonth,
    end_date: endOfMonth,
  };
  parentId && (body.parent_fh_id = parentId);
  const data = await post("funeralHomeData", token, body);
  const obituaries = data.traffic?.filter((data) => data.type === "obituary");
  const directories = data.traffic?.filter((data) => data.type === "directory");
  const websiteClicks = data.clicks?.filter((data) => data.type === "website");
  const directionClicks = data.clicks?.filter(
    (data) => data.type === "directions"
  );
  const phoneClicks = data.clicks?.filter((data) => data.type === "phone");
  const obitVolume = data.obit_volume;
  const flowerOrders = data.flowers_count;
  return {
    marketshare: data.market_share,
    obituaries: obituaries,
    funeral_home: data.funeral_home,
    directories: directories,
    obit_volume: obitVolume || [],
    flowers_count: flowerOrders || [],
    events: {
      websiteClicks: websiteClicks || [],
      directionClicks: directionClicks || [],
      phoneClicks: phoneClicks || [],
    },
  };
};

export const getDailyFuneralHomeData = async (id, token) => {
  const currentDate = new Date();
  let body = {
    fh_id: id,
    start_date: currentDate.toISOString(),
    end_date: currentDate.toISOString(),
  };
  return post("funeralHomeData", token, body);
};

export const getMostRecentFuneralHomeData = async (id, token) => {
  const data = await post("funeralHomeData", token, { fh_id: id });

  if (data?.traffic) {
    return data.traffic[data.traffic.length - 1];
  } else {
    return null;
  }
};

export const updateFuneralHome = async (payload, token) => {
  return patch("funeral-homes", token, payload);
};

export const uploadCompetitors = async (payload, token) => {
  return post("funeralHomeCompetitors", token, payload);
};

export const uploadFlowers = async (payload, token) => {
  return post("uploadLegacyFlowers", token, payload);
};

export const uploadObits = async (payload, token) => {
  return post("uploadLegacyObituaries", token, payload);
};

export const uploadSpotlightFlowers = async (payload, token) => {
  return post("uploadLocalSpotlightFlowers", token, payload);
};

export const uploadSpotlightObits = async (payload, token) => {
  return post("uploadLocalSpotlightObits", token, payload);
};

export const uploadMonthlyDataForHomes = async (payload, token) => {
  return post("monthlyDataForHomes", token, payload);
};

export const patchFlowerById = async (id, token, body) => {
  return patch(`legacy-flowers/${id}`, token, body);
};

export const patchLSFlowerById = async (id, token, body) => {
  return patch(`fhls-flowers/${id}`, token, body);
};

export const patchObitById = async (id, token, body) => {
  return patch(`legacy-obits/${id}`, token, body);
};

export const patchLSObitById = async (id, token, body) => {
  return patch(`fhls-obits/${id}`, token, body);
};

export const createFlowerEntry = async (token, payload) => {
  return post("legacy-flowers", token, payload);
};

export const createLSFlowerEntry = async (token, payload) => {
  return post("fhls-flowers", token, payload);
};

export const createObitEntry = async (token, payload) => {
  return post("legacy-obits", token, payload);
};

export const createLSObitEntry = async (token, payload) => {
  return post("fhls-obits", token, payload);
};

export const addCompetitor = async (token, payload) => {
  return post("fh-competitor", token, payload);
};

export const convertCompetitor = async (id, token, payload) => {
  return post(`funeral-homes/${id}/make-client`, token, payload);
};

export const addUserAdmin = async (token, payload) => {
  return post("users/add-corporation-admin", token, payload);
};

export const getChiefCorporationOverview = async (token, payload) => {
  return post("chief-corporation-data", token, payload);
};

export const getCorporationParentsByCorporationId = async (token, corp_id) => {
  return get(`corporation-parents/${corp_id}`, token);
};

export const getChiefBrandReach = async (token, payload) => {
  return post("chief-corporation-brand-reach", token, payload);
};
