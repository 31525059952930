import React from "react";
import PropTypes from "prop-types";

import {
  InputStyled,
  TextLabel,
  LabelStyled,
  ErrorMessage,
} from "./Input.styled";

const Input = ({
  error,
  isValid,
  label,
  type,
  className,
  marginBottom,
  register,
  name,
  id,
  placeholder,
  htmlFor,
  ...inputProps
}) => {
  return (
    <LabelStyled
      isValid={isValid}
      hasError={error}
      className={className}
      marginBottom={marginBottom}
    >
      <TextLabel>{label}</TextLabel>
      <hr />
      <InputStyled
        ref={register}
        {...{
          type,
          id,
          className,
          inputProps,
          placeholder,
          name,
          htmlFor,
        }}
      />
      <ErrorMessage>{error}</ErrorMessage>
    </LabelStyled>
  );
};

Input.defaultProps = {
  type: "text",
  marginBottom: true,
};

Input.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
  marginBottom: PropTypes.bool,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  register: PropTypes.func,
};

export default Input;
