import React from "react";
import styled from "styled-components";
import { Flex, Text } from "..";
import { colors, media, sizing } from "utils";

export const Wrapper = styled.div`
  max-width: 70.4rem;
  margin: 0 auto;
`;

export const Title = styled((props) => <Text {...props} />)`
  margin-bottom: ${({ type }) => (type !== "Donut" ? sizing._450 : 0)};
  ${media.tabletPlus`
		margin-bottom: ${({ type }) => (type !== "Donut" ? sizing._450 : sizing._200)};
	`}
`;

export const WidthProvider = styled.div`
  width: ${({ width }) => `${width}px`};
`;

export const HeightProvider = styled.div`
  height: ${({ height }) => height};
  &.donut {
    height: 300px;
    ${media.tabletPlus`
  		height: 500px;
  	`}
  }
`;

export const Key = styled((props) => <Flex {...props} />)`
  width: 20%;
`;
export const DonutWrapper = styled.div`
  width: 80%;
`;

export const KeyItem = styled.div`
  padding-bottom: 1.3rem;
  ${media.tabletPlus`
		padding-bottom: 2.5rem;
	`}
`;

export const KeyItemColor = styled.div`
  background: ${({ color }) => color};
  height: 1.8rem;
  width: 3.5rem;
  margin-right: 1.3rem;
`;

export const DonutHole = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const Remainder = styled.div`
  height: 8px;
  background: url("bar-placeholder.svg");
  background-repeat: round;
  background-size: 9px;
  ${({ chartWidth }) => `width: calc(100% - ${chartWidth}px);`}
`;

export const LegendSquare = styled.div`
  height: 16px;
  width: 16px;
  background: ${({ color }) => color};
`;

export const Tooltip = styled.div`
  position: absolute;
  width: 120px;
  height: 55px;
  top: -80px;
  left: 0;
  text-transform: capitalize;
  z-index: 99999;
`;

export const TooltipBackground = styled.div`
  padding: 6px;
  background: ${colors.WHITE};
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
`;
