import styled from "styled-components";
import { colors, media } from "utils";
import { Flex } from "components";
import { ReactComponent as Logo } from "assets/icons/legacyComWhite.svg";

export const Wrapper = styled.div`
  background: #00141e;
  padding: 32px 32px 36px;
  width: 100%;
  text-align: center;
`;

export const LinkWrapper = styled(Flex)`
  margin: 32px 0 16px;
  flex-direction: column;
  ${media.tablet`
        flex-direction: row;
    `}
  a {
    position: relative;
    ${media.tablet`
      margin: 0 16px;
    `}
  }
`;

export const FooterLink = styled.a`
  text-decoration: none;
`;

export const SocialMediaWrapper = styled(Flex)`
  padding: 0 0 32px;
  margin: 0 auto 32px auto;
  flex-direction: column;
  width: 90%;
  ${media.tablet`
      flex-direction: row;
    `}
  svg {
    margin: 8px 0 8px 0;
    ${media.tablet`
      margin: 0 8px 0 8px;
    `}
  }
  border-bottom: 1px solid ${colors.NEUTRAL_75};
`;

export const SmallLogo = styled(Logo)`
  height: 32px;
  width: 172px;
`;
