import React from "react";
import { Text } from "..";
import styled from "styled-components";
import { colors, media } from "utils";

export const Card = styled.div`
  background: ${colors.WHITE};
  border-radius: 4px;
  padding: 24px;
  float: left;
  margin: 8px 0;
  min-width: 312px;
  box-shadow: -2px 5px 9px ${colors.NEUTRAL_20};
  border-radius: 10px;
  position: relative;
  overflow-y: scroll;
  ${media.tablet`
    overflow: visible;
  `}

  &.petit {
    min-width: 100px;
    width: 100%;
    max-width: 100%;
    height: 124px;
    max-height: 124px;
    padding: 18px;
    ${media.tablet`
      max-width: 200px;
    `}
  }

  &.small {
    min-width: 200px;
    width: 100%;
    max-width: 300px;
  }

  &.responsiveChart {
    max-width: unset;
    width: 100%;
  }

  &.responsiveGrid {
    min-width: unset;
    width: 100%;
    margin: unset;
  }
`;

export const TrendArrow = styled.span`
  transform: scale(2);
  &.up {
    transform: scale(2) rotate(180deg);
    color: green;
  }
  &.down {
    color: red;
  }
`;

export const Value = styled((props) => <Text {...props} />)`
  position: relative;
  margin-left: 15px;
`;

export const GlossaryIcon = styled.div`
  position: absolute;
  top: 29px;
  right: 24px;
  border: 2px solid ${colors.NEUTRAL_20};
  color: ${colors.NEUTRAL_20};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
`;

export const FilterIcon = styled.img`
  position: absolute;
  top: 8px;
  right: 12px;
  ${media.tabletPlus`
    top: 26px;
    right: 48px;
  `}
`;

export const Modal = styled.div`
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${colors.NEUTRAL_05};
  box-shadow: rgba(0, 20, 30, 0.2) 0px 4px 4px -2px;
  position: absolute;
  max-width: 264px;
  background: ${colors.WHITE};
  top: 45px;
  right: 24px;
`;
