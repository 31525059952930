import React, { useCallback, useState, useEffect } from "react";
import { Flex, LegacyLogo } from "components";
import { ReactComponent as MenuIcon } from "assets/icons/hamburgerMenu.svg";
import * as Styled from "./Sidebar.styled";
import { useToken, useUser } from "providers/AuthProvider";
import { getCorporationParentsByCorporationId } from "api/funeral-home";
import { useLocation } from "@reach/router";

const Sidebar = ({
  data,
  active,
  selectHome,
  title,
  toggleMenu,
  isMobile,
  parentId,
  parentSelected,
  setParentSelected,
  className,
  grandparentSelected,
  setGrandparentSelected,
  activeMenuIndex,
  funeral_home,
  leadPartner,
}) => {
  const SuperAdMenu = React.lazy(() => import("./SuperAdMenu"));
  const FhMenu = React.lazy(() => import("./FhMenu"));
  const CorporateMenu = React.lazy(() => import("./CoporateMenu"));
  const PartnerMenu = React.lazy(() => import("./PartnerMenu"));

  const { pathname } = useLocation();

  const [user] = useUser();
  const [token] = useToken();
  const [parentHomes, setParentHomes] = useState();
  const viewAsId = sessionStorage.getItem("viewAs");
  const viewAsRole = sessionStorage.getItem("role");
  const userCorporationId = user?.corporation;

  const getParents = useCallback(async () => {
    const parentHomes = await getCorporationParentsByCorporationId(
      token,
      viewAsId ?? userCorporationId
    );
    setParentHomes(parentHomes);
  }, [token, userCorporationId, viewAsId]);

  useEffect(() => {
    if (
      user?.type === "chiefCorporationAdmin" ||
      user?.corporation ||
      viewAsRole === "chiefCorporationAdmin"
    ) {
      getParents();
    }
  }, [getParents, user, viewAsRole]);

  // FOR SUPER ADMIN USER
  if (user?.type === "superAdmin" && !leadPartner && !viewAsRole) {
    return (
      <Styled.Sidebar className={className}>
        <Styled.Head>
          <Flex align="center" height="100%">
            <LegacyLogo />
            {isMobile && (
              <Styled.MenuIcon>
                <MenuIcon onClick={toggleMenu} />
              </Styled.MenuIcon>
            )}
          </Flex>
        </Styled.Head>
        <SuperAdMenu />
      </Styled.Sidebar>
    );
  }

  // FOR LEAD PARTNER USER
  if (leadPartner || pathname.includes("lead-partner")) {
    return (
      <Styled.Sidebar className={className}>
        <Styled.Head>
          <Flex align="center" height="100%">
            <LegacyLogo />
            {isMobile && (
              <Styled.MenuIcon>
                <MenuIcon onClick={toggleMenu} />
              </Styled.MenuIcon>
            )}
          </Flex>
        </Styled.Head>
        <PartnerMenu />
      </Styled.Sidebar>
    );
  }

  // FOR CHIEF CORPORATE ADMIN USER
  if (
    user?.type === "chiefCorporationAdmin" ||
    user?.corporation ||
    viewAsRole === "chiefCorporationAdmin"
  ) {
    return (
      <Styled.Sidebar className={className}>
        <Styled.Head>
          <Flex align="center" height="100%">
            <LegacyLogo />
            {isMobile && (
              <Styled.MenuIcon>
                <MenuIcon onClick={toggleMenu} />
              </Styled.MenuIcon>
            )}
          </Flex>
        </Styled.Head>
        <CorporateMenu
          {...{
            active,
            activeMenuIndex,
            data,
            grandparentSelected,
            parentHomes,
            parentSelected,
            selectHome,
            setGrandparentSelected,
            setParentSelected,
            user,
            viewAsId,
            viewAsRole,
          }}
        />
      </Styled.Sidebar>
    );
  }

  // FOR FUNERAL HOME USER
  return (
    <Styled.Sidebar className={className}>
      <Styled.Head>
        <Flex align="center" height="100%">
          <LegacyLogo />
          {isMobile && (
            <Styled.MenuIcon>
              <MenuIcon onClick={toggleMenu} />
            </Styled.MenuIcon>
          )}
        </Flex>
      </Styled.Head>
      <FhMenu
        {...{
          active,
          data,
          funeral_home,
          leadPartner,
          parentId,
          parentSelected,
          selectHome,
          title,
        }}
      />
    </Styled.Sidebar>
  );
};

export default Sidebar;
