const url = window.location.href.includes("pro.legacy")
  ? process.env.REACT_APP_PRODUCTION_API
  : process.env.REACT_APP_STAGE_API;

const request = (method) => async (endpoint, token, body) => {
  // Send the request
  const headers = { "Content-Type": "application/json" };
  if (token) headers["Authorization"] = `Bearer ${token}`;
  if (body && typeof body === "object") body = JSON.stringify(body);
  const res = await fetch(url + endpoint, { method, body, headers });

  // Parse the response
  try {
    if (!res.headers.get("content-type").includes("json")) return res.text();
    const data = await res.json();
    if (String(res.status)[0] !== "2") {
      throw data.message || "Error";
    }
    return data;
  } catch (e) {
    throw e;
  }
};

export const del = request("DELETE");
export const get = request("GET");
export const post = request("POST");
export const put = request("PUT");
export const patch = request("PATCH");
