import styled from "styled-components";
import { colors, media } from "utils";

const SIZE_25 = ".4rem";
const SIZE_50 = ".8rem";
const SIZE_100 = "1.2rem";
const SIZE_150 = "1.4rem";
const SIZE_200 = "1.6rem";
const SIZE_250 = "2rem";
const SIZE_300 = "2.4rem";
const SIZE_400 = "2.8rem";
const SIZE_450 = "3.2rem";
const SIZE_500 = "3.6rem";
const SIZE_600 = "4.8rem";
const SIZE_700 = "5.6rem";
const SIZE_800 = "6.4rem";
const SIZE_900 = "9.6rem";
const SIZE_1000 = "12.8rem";

const FONT_WEIGHT_THIN = 100;
const FONT_WEIGHT_LIGHT = 200;
const FONT_WEIGHT_BOOK = 300;
const FONT_WEIGHT_MEDIUM = 500;
const FONT_WEIGHT_BOLD = 600;
const FONT_WEIGHT_BLACK = 900;

export const spacing = Object.freeze({
  SIZE_25,
  SIZE_50,
  SIZE_100,
  SIZE_150,
  SIZE_200,
  SIZE_250,
  SIZE_300,
  SIZE_400,
  SIZE_450,
  SIZE_500,
  SIZE_600,
  SIZE_700,
  SIZE_800,
  SIZE_900,
  SIZE_1000,
});

export const font = Object.freeze({
  SIZE_25,
  SIZE_50,
  SIZE_100,
  SIZE_150,
  SIZE_200,
  SIZE_250,
  SIZE_300,
  SIZE_400,
  SIZE_450,
  SIZE_500,
  SIZE_600,
  SIZE_700,
  SIZE_800,
  SIZE_900,
  SIZE_1000,
  WEIGHT_THIN: FONT_WEIGHT_THIN,
  WEIGHT_LIGHT: FONT_WEIGHT_LIGHT,
  WEIGHT_BOOK: FONT_WEIGHT_BOOK,
  WEIGHT_MEDIUM: FONT_WEIGHT_MEDIUM,
  WEIGHT_BOLD: FONT_WEIGHT_BOLD,
  WEIGHT_BLACK: FONT_WEIGHT_BLACK,
});

export const ErrorMessage = styled.span`
  bottom: -${spacing.SIZE_250};
  color: ${colors.ERROR};
  font-size: ${font.SIZE_200};
  font-weight: ${font.WEIGHT_LIGHT};
  left: 0;
  line-height: 1.25;
  position: absolute;
`;

export const InputStyled = styled.input`
  margin: ${({ margin }) => margin};
  border: 1px solid ${colors.NEUTRAL_20};
  box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.1);
  font-size: ${font.SIZE_200};
  padding: ${spacing.SIZE_150} ${spacing.SIZE_200};
  width: 100%;
  line-height: 1.25;
  /* &.half-width {
    width: 40%;
  } */
  &::placeholder {
    color: ${colors.NEUTRAL_20};
  }
`;

export const LabelStyled = styled.label`
  display: block;
  margin-bottom: ${(props) => (props.marginBottom ? spacing.SIZE_500 : 0)};
  position: relative;
  width: 100%;
  &.half-width {
    width: 100%;
    ${media.tabletPlus`
      width: 336px;
    `}/* hr {
      border: none;
      border-top: 1px solid
        ${(props) => {
      if (props.hasError) return colors.salmon50;
      if (props.isValid) return colors.green50;
      return "transparent";
    }};
      margin-bottom: 1rem;
      margin-top: -1.2rem;
    }

    &:focus-within hr {
      border-top: 1px solid ${colors.NEUTRAL_40};
    } */
  }

  hr {
    border: none;
    border-top: 1px solid
      ${(props) => {
        if (props.hasError) return colors.ERROR;
        if (props.isValid) return colors.SUCCESS;
        return "transparent";
      }};
    margin-bottom: 1rem;
    margin-top: -1.2rem;
  }

  &:focus-within hr {
    border-top: 1px solid ${colors.NEUTRAL_40};
  }
`;

export const TextLabel = styled.span`
  background: ${(props) => props.theme.background};
  display: inline-block;
  font-size: ${font.SIZE_150};
  color: ${colors.accessibilityBlack};
  line-height: 1.5;
  margin-bottom: ${spacing.SIZE_25};
  padding-right: ${spacing.SIZE_50};
`;

TextLabel.defaultProps = {
  theme: {
    background: colors.BASE,
  },
};
