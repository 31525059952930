import React from "react";
import ReactDOM from "react-dom";
import Analytics from "analytics";
import { AnalyticsProvider } from "use-analytics";
import googleAnalytics from "@analytics/google-analytics";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import colors from "./utils/colors";

const analytics = Analytics({
  app: "legacy-pro-dashboard",
  plugins: [
    googleAnalytics({
      trackingId: "UA-3821886-13",
    }),
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider instance={analytics}>
      <ToastContainer
        position={"bottom-center"}
        progressStyle={{ background: colors.NEUTRAL_75 }}
        bodyStyle={{ color: colors.NEUTRAL }}
        autoClose={3000}
      />
      <App />
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
