/**
 > > Sample of JSON data with validation errors

 ====== Subscriber Upload ======
 const sampleData = [
    {
        user_name: "Test One",
        email: "paxton+one@builtbyhq.com",
        parent_fh_id: "hq111",
        fh_id: "",
        fh_name: "Parent Home",
        contract_signed: "FALSE",
        fh_display: "Parent Home"
    },
    {
        user_name: "Test Two",
        email: "paxton+two@builtbyhq.com",
        parent_fh_id: "hq111",
        fh_id: "hq112",
        fh_name: "",
        contract_signed: "FALSE",
        fh_display: "Child Home One"
    },
    {
        user_name: "Test Three",
        email: "",
        parent_fh_id: "hq111",
        fh_id: "hq113",
        fh_name: "Child Home Two",
        contract_signed: "FALSE",
        fh_display: "Child Home Two"
    },
    {
        user_name: "",
        email: "paxton+four@builtbyhq.com",
        parent_fh_id: "",
        fh_id: "hq114",
        fh_name: "Child Home Three",
        contract_signed: "",
        fh_display: ""
    }
]

====== Competitor Upload ======
const sampleData = [
  {
    fh_id: 27371,
    report: "Whitney & Murphy Funeral Home A",
    relationship: "Client",
    fh_name: "Whitney & Murphy A",
    fh_display: "Whitney & Murphy Funeral Home A",
    parent_fh_id: 27371,
  },
  {
    fh_id: 37371,
    report: "Whitney & Murphy Funeral Home C",
    relationship: "Client",
    fh_name: "Whitney & Murphy",
    fh_display: "Whitney & Murphy Funeral Home C",
    parent_fh_id: 27371,
  },
  {
    fh_id: 47371,
    report: "Whitney & Murphy Funeral Home C",
    relationship: "Client",
    fh_name: "Whitney & Murphy C",
    fh_display: "Whitney & Murphy Funeral Home C",
    parent_fh_id: 27371,
  },
];
*/

const competitor = (data) => {
  const invalid = [];

  const validated = data.map((item, index) => {
    // Check for required fields and throw error if null
    !item.fh_id && invalid.push(`Row ${index + 1} needs an fh_id`);
    !item.client_fh_id && invalid.push(`Row ${index + 1} needs a client_fh_id`);
    !item.fh_name && invalid.push(`Row ${index + 1} needs an fh_name`);

    // Check optional fields and apply defaults
    !item.parent_fh_id && (item.parent_fh_id = null);
    !item.fh_display && (item.fh_display = item.fh_name || null);

    return item;
  });

  return invalid.length > 0
    ? { invalidations: invalid }
    : { validated: validated };
};

const flower = (data) => {
  const invalid = [];

  const validated = data.map((item, index) => {
    // Check for required fields and throw error if null
    !item.fh_id && invalid.push(`Row ${index + 1} needs an fh_id`);
    !item.orders && invalid.push(`Row ${index + 1} needs orders`);

    return item;
  });

  return invalid.length > 0
    ? { invalidations: invalid }
    : { validated: validated };
};

const obituary = (data) => {
  const invalid = [];

  const validated = data.map((item, index) => {
    // Check for required fields and throw error if null
    !item.fh_id && invalid.push(`Row ${index + 1} needs an fh_id`);
    !item.parent_fh_id && invalid.push(`Row ${index + 1} needs a parent_fh_id`);
    !item.month && invalid.push(`Row ${index + 1} needs a month`);
    !item.year && invalid.push(`Row ${index + 1} needs a year`);
    !item.obit_count && invalid.push(`Row ${index + 1} needs an obit_count`);

    return item;
  });

  return invalid.length > 0
    ? { invalidations: invalid }
    : { validated: validated };
};

const spotlightFlower = (data) => {
  const invalid = [];

  const validated = data.map((item, index) => {
    // Check for required fields and throw error if null
    !item.FHID && invalid.push(`Row ${index + 1} needs an FHID`);
    !item.Month && invalid.push(`Row ${index + 1} needs a Month`);
    !item.Year && invalid.push(`Row ${index + 1} needs a Year`);
    !item.Orders && invalid.push(`Row ${index + 1} need Orders`);

    return item;
  });

  return invalid.length > 0
    ? { invalidations: invalid }
    : { validated: validated };
};

const spotlightObit = (data) => {
  const invalid = [];

  const validated = data.map((item, index) => {
    // Check for required fields and throw error if null
    !item.FHID && invalid.push(`Row ${index + 1} needs an FHID`);
    !item.Month && invalid.push(`Row ${index + 1} needs a Month`);
    !item.Year && invalid.push(`Row ${index + 1} needs a Year`);
    !item.NewStandardPaidNotices &&
      invalid.push(`Row ${index + 1} needs a NewStandardPaidNotices`);
    !item.NewNGOPaidNotices &&
      invalid.push(`Row ${index + 1} needs a NewNGOPaidNotices`);
    !item.NewFHLSPaidNotices &&
      invalid.push(`Row ${index + 1} needs a NewFHLSPaidNotices`);
    !item.NewFreeNotices &&
      invalid.push(`Row ${index + 1} needs a NewFreeNotices`);

    return item;
  });

  return invalid.length > 0
    ? { invalidations: invalid }
    : { validated: validated };
};

const googleAnalytics = (data) => {
  const invalid = [];

  const validated = data.map((item, index) => {
    // Check for required fields and throw error if null
    !item.fh_id && invalid.push(`Row ${index + 1} needs an fh_id`);
    !item.parent_fh_id && invalid.push(`Row ${index + 1} needs a parent_fh_id`);

    return item;
  });

  return invalid.length > 0
    ? { invalidations: invalid }
    : { validated: validated };
};

const subscriber = (data) => {
  const invalid = [];

  const validated = data.map((item, index) => {
    // Check for required fields and throw error if null
    !item.user_name && invalid.push(`Row ${index + 1} needs a user_name`);
    !item.fh_name && invalid.push(`Row ${index + 1} needs an fh_name`);
    !item.fh_id && invalid.push(`Row ${index + 1} needs an fh_id`);
    !item.email && invalid.push(`Row ${index + 1} needs a email`);

    // Check optional fields and apply defaults
    !item.parent_fh_id && (item.parent_fh_id = null);
    !item.contract_signed && (item.contract_signed = null);
    !item.fh_display && (item.fh_display = item.fh_name || null);

    // Convert string booleans to valid booleans
    item.local_spotlight === "FALSE" && (item.local_spotlight = false);
    item.local_spotlight === "TRUE" && (item.local_spotlight = true);
    item.contract_signed === "FALSE" && (item.contract_signed = false);
    item.contract_signed === "TRUE" && (item.contract_signed = true);
    item.market_share === "FALSE" && (item.market_share = false);
    item.market_share === "TRUE" && (item.market_share = true);

    return item;
  });

  return invalid.length > 0
    ? { invalidations: invalid }
    : { validated: validated };
};

export default {
  subscriber,
  competitor,
  flower,
  obituary,
  spotlightFlower,
  spotlightObit,
  googleAnalytics,
};
