import styled from "styled-components";
import { colors, media } from "utils";
import { Flex } from "components";

export const TabWrapper = styled(Flex)`
  border-bottom: 1px solid ${colors.NEUTRAL_20};
  margin: 24px 0 24px;
  position: relative;
  flex-direction: column;
  overflow: auto;
  ${media.tablet`
    margin: 56px 0 56px;
    justify-content: flex-start;
    flex-direction: row;
  `}
`;

export const TabItem = styled(Flex)`
  // margin-right: 30px;
  margin-bottom: 8px;
  white-space: nowrap;
  padding-bottom: 7px;
  ${media.tablet`
    margin-bottom: 0;
    margin-right: 35px;
  `}
  &:last-of-type {
    margin-right: 0px;
  }

  &:hover {
    cursor: pointer;
    border-bottom: 4px solid ${colors.GOLD};

    .text-field {
      color: ${colors.GOLD};
    }
  }

  .text-field {
    border-bottom: 2px solid transparent;
    margin-bottom: -1px;
  }

  &.active {
    border-bottom: 4px solid ${colors.GOLD};
    .text-field {
      color: ${colors.GOLD};
      font-weight: bold;
    }
  }
`;
