import { navigate } from "@reach/router";

const logout = async (options) => {
  if (sessionStorage.getItem("viewAs") || sessionStorage.getItem("role")) {
    window.close();
  } else {
    localStorage.removeItem(
      `@@auth0res@@::${process.env.REACT_APP_AUTH0_CLIENTID}::${process.env.REACT_APP_AUTH0_AUDIENCE}`
    );

    navigate(window.location.origin);
    if (options?.from === "loading") window.location.reload();
  }
};

export default logout;
