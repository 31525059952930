// export default {
//   gold100: "#B39464",
//   gold200: "#B49561",

//   green100: "#44A45F",
//   greenCheck: "#4AC15E",

//   blue100: "#0D6797",
//   blue200: "#006699",
//   blue300: "#2096F3",
//   blue500: "#06273C",

//   whiter: "#FFFFFF",
//   white: "#FAFAFA",

//   gray10: "#F2F2F2",
//   gray50: "#E5E5E5",
//   gray100: "#F2F3F4",
//   gray200: "#CCD0D2",
//   gray300: "#7F898E",
//   gray400: "#404F57",
//   gray450: "#3A4950",
//   gray500: "#959595",

//   black: "#01141E",

//   red: "#E06363",
//   redAlert: "#FC3158",
//   red300: "#b0230b",

/************************************************************
This follows the exact color scheme from Legacy's Brand Guide
*************************************************************/

const colors = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",

  BASE: "#FAFAFA", //white
  NEUTRAL: "#00141E",
  NEUTRAL_04: "#F2F2F2", //gray10:
  NEUTRAL_05: "#F2F3F4", //gray100
  NEUTRAL_20: "#CCD0D2", //gray200
  NEUTRAL_30: "#979797",
  NEUTRAL_40: "#959595", //gray500
  NEUTRAL_50: "#7F898E", //gray300
  NEUTRAL_75: "#404F57", //gray400
  NEUTRAL_80: "#00141ECC",

  BLUE: "#006699", //blue200
  BLUE_10: "#E6F0F5",
  BLUE_20: "#BFD8E5",
  BLUE_50: "#80B2CC",
  BLUE_75: "#408CB2",
  BLUE_85: "#2096F3", //blue300
  BLUE_100: "#0D6797", //blue100
  BLUE_110: "#005D8C",
  BLUE_120: "#003C5B",
  BLUE_130: "#00283D", //blue500

  GREEN_75: "#4AC15E", //greenCheck
  GREEN_100: "#44A45F", //green100

  RED_200: "#FC3158", //redAlert
  RED_300: "#b0230b", //red300
  RED_400: "#b91c1c", //red
  RED_200_TRANSPARENT: "#FC3158CC", //rgb(252,49,88)
  RED_400_TRANSPARENT: "#b91c1cCC", //rgb(185,28,28)

  GOLD: "#B49561", //gold200
  GOLD_10: "#C7B089",
  GOLD_20: "#F0EADF",
  GOLD_50: "#D9CAB0",
  GOLD_75: "#C7B089",
  GOLD_100: "#B39464", //gold100
  GOLD_110: "#A1875A",
  GOLD_120: "#907B53",
  GOLD_130: "#7D6E4C",
  GOLD_150: "#59543F",
  GOLD_175: "#2D342F",
};

const readable = {
  PRIMARY: colors.BLUE,
  SECONDARY: colors.GOLD,

  SUCCESS: "#0ABD64",
  ERROR: "#E06363",
  WARNING: "#F4EE7B",

  // Gradients
  AZURE: `linear-gradient(to top right, ${colors.BLUE}, #003C5B)`,
  CARBON: `linear-gradient(to top right, #404F57, ${colors.BLACK})`,
  MIDNIGHT: `linear-gradient(to top right, #00283D, ${colors.BLACK})`,
  TWILIGHT: `linear-gradient(to top, #00283D, ${colors.NEUTRAL_80})`,
  GILDED: `linear-gradient(to top right, ${colors.GOLD_120}, #C3B28B)`,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { ...colors, ...readable };
