import React, { createContext, useContext, useEffect, useState } from "react";
import { get } from "api/request";
import LoadingPage from "../pages/LoadingPage";
import { getToken, logout } from "utils";
const UserContext = createContext(null);
const TokenContext = createContext(null);
const LoadingContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [localUser, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setToken(getToken());
  }, [localUser]);
  // When the access token changes, request the user from the API
  useEffect(() => {
    (async () => {
      if (token) {
        try {
          const loggedInUser = await get("user", token);
          if (loggedInUser.user_id) {
            setUser(loggedInUser);
            localStorage.setItem("type", loggedInUser.type);
          } else if (loggedInUser.statusCode === 401) {
            setError("No Funeral Home associated with this email");
          } else {
            localStorage.setItem("type", "");
            logout();
            setError(null);
          }
        } catch (e) {
          localStorage.setItem("type", "");
          setError(
            "There is either no funeral home or no user associated with this email. Please return to login and try a different account."
          );
        }
      }
    })();
  }, [token]);

  return (
    <UserContext.Provider value={[localUser, setUser]}>
      <TokenContext.Provider value={[token, setToken]}>
        <LoadingContext.Provider value={[isLoading, setIsLoading]}>
          {isLoading && !localUser?.user_id ? <LoadingPage /> : children}
          {error && <LoadingPage error={error} />}
        </LoadingContext.Provider>
      </TokenContext.Provider>
    </UserContext.Provider>
  );
};

// Exports
export const useUser = () => useContext(UserContext);
export const useToken = () => useContext(TokenContext);
export const useLoading = () => useContext(LoadingContext);
export default AuthProvider;
