const generateTooltip = (label, category) => {
  if (label === "Users") {
    switch (category) {
      case "obituary":
        return "The number of users that viewed your  Obituary content on Legacy.com during the selected time period.";
      case "directory":
        return "The number of users that viewed your  directory page on Legacy.com during the selected time period. ";
      default:
        return "";
    }
  } else if (label === "Page Views") {
    switch (category) {
      case "obituary":
        return "The number of times your Obituaries have been viewed during the selected time period.";
      case "directory":
        return "The number of times your Funeral Home's Legacy.com directory page has been viewed over the selected time period.";
      default:
        return "";
    }
  } else if (label === "Sessions") {
    switch (category) {
      case "obituary":
        return "Total number of sessions generated on your Obituary content during the selected time period.";
      case "directory":
        return "Total number of sessions generated on your directory page during the selected time period.";
      default:
        return "";
    }
  } else if (label === "Website Clicks") {
    return "The number of times a user has clicked on the link to your website from your Legacy.com directory page.";
  } else if (label === "Lead Form Clicks") {
    return "The number of times a user has clicked the 'Contact Us' link on your Legacy.com directory page.";
  } else if (label === "Phone Number Clicks") {
    return "The number of times a user has clicked the Phone number on your Legacy.com directory page.";
  } else if (label === "Obituaries Published") {
    return "The number of your Obituaries collected and published on Legacy.com over the selected time period.";
  } else if (label === "Sympathy Flower Orders") {
    return "The number of Sympathy Flower orders generated from your Directory and Obituary pages on Legacy.com over the selected time period.";
  } else {
    return "";
  }
};

export default generateTooltip;
