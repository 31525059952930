import React from "react";
import { Flex } from "components";
import { Pro, Title } from "./LegacyLogo.styled";

const LegacyLogo = ({ kind, logoSize, gap, ...rest }) => {
  return (
    <Flex justify="flex-start" gap={gap ?? "0"}>
      <Title color="BASE" kind={kind ?? "f3 serif"} {...rest}>
        Legacy
      </Title>
      <Pro logoSize={logoSize}>PRO</Pro>
    </Flex>
  );
};

export default LegacyLogo;
