const monthsObject = [
  { number: "01", shortName: "Jan", fullName: "January" },
  { number: "02", shortName: "Feb", fullName: "February" },
  { number: "03", shortName: "Mar", fullName: "March" },
  { number: "04", shortName: "Apr", fullName: "April" },
  { number: "05", shortName: "May", fullName: "May" },
  { number: "06", shortName: "Jun", fullName: "June" },
  { number: "07", shortName: "Jul", fullName: "July" },
  { number: "08", shortName: "Aug", fullName: "August" },
  { number: "09", shortName: "Sep", fullName: "September" },
  { number: "10", shortName: "Oct", fullName: "October" },
  { number: "11", shortName: "Nov", fullName: "November" },
  { number: "12", shortName: "Dec", fullName: "December" },
];

export default monthsObject;
