import styled from "styled-components";
import { Flex } from "components";
import { colors, media } from "utils";

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin = "12px" }) => margin};
  color: ${colors.gray3F};
  width: 350px;
  border-bottom: 1px solid ${colors.NEUTRAL_20};
  padding: 8px;
  text-align: center;
  input {
    width: 270px;
    outline: none;
  }
  span {
    cursor: pointer;
    margin-left: 12px;
  }
`;

export const Carrot = styled.div`
  position: absolute;
  top: 20%;
  left: -2px;
  padding: 4px;
  border-radius: 50%;
  margin-right: 4px;
  &.down {
    transform: rotate(180deg);
    top: 18%;
  }
  &:after {
    content: "▴";
    font-size: 14px;
  }
  &:hover {
    border: 1px dashed black;
  }
`;

export const OverflowContainer = styled(Flex)`
  align-items: flex-start;
  width: 100%;
  position: relative;
`;

export const Loader = styled.div`
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Wrapper = styled.div`
  margin: ${({ margin = "0" }) => margin};
  scroll-behavior: smooth;
  width: 240%;
  border-right: 32px solid transparent;
  @media (min-width: 540px) {
    width: 140%;
  }
  ${media.tablet`
    width: 100%;
    border-right: none;
  `}
  background-color: ${colors.WHITE};
  box-shadow: 0 4px 4px -2px rgba(0, 20, 30, 0.2);
  border-radius: 4px;
  padding: 1rem;
  ${"" /* These styles are suggested for the table fill all available space in its containing element */}
  display: block;
  ${"" /* These styles are required for a horizontally scrollable table overflow */}
  overflow: auto;
  &.clickable {
    .td {
      cursor: pointer;
    }
  }

  &.chiefCorpOverview {
    min-width: 750px;
  }

  &.localAds {
    margin-bottom: 36px;
  }

  & > div.sort-icon {
    margin-right: 4px;
    font-size: 0.6em;
  }

  .table {
    border-spacing: 0;

    // NOTE: REMOVED 09 NOVEMBER 2022 B/C NO HORIZONTAL SCROLL
    .thead {
      // overflow-y: auto;
      // overflow-x: hidden;
    }

    .tbody {
      // overflow-y: scroll;
      // overflow-x: hidden;
    }

    .tr {
      display: flex;
      &.isExpandable {
        &:hover {
          transform: scale(1.01) translateY(2px);
          font-weight: 600 !important;
          box-shadow: 0 4px 4px -2px rgba(0, 20, 30, 0.3);
          cursor: pointer;
        }
      }

      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid ${colors.NEUTRAL_05};
    }

    .th {
      color: ${colors.gray3F};
      font-size: 16px;
      position: relative;
      padding: 4px 1rem;
      margin: 0 0 10px;
      border-bottom: 1px solid ${colors.NEUTRAL_05};
    }

    .td {
      color: ${colors.black200};
      font-size: 14px;
    }

    .td {
      margin: 0;
      padding: 1rem;
      position: relative;
      :last-child {
        border-right: 0;
      }

      .resizer {
        right: 0;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        &:hover {
          background: rgba(64, 79, 87, 0.6);
        }

        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;

        &.isResizing {
          background: ${colors.GOLD};
        }
      }
    }
  }
`;
