/*************************************************************
 *       BUTTON COMPONENT FOR CHANGING ICONS ON HOVER
 *                    UTILIZES REACT-ICONS
 *  READ MORE HERE https://react-icons.github.io/react-icons/
 **************************************************************/

import React, { useState } from "react";
import styled from "@emotion/styled";
import { colors } from "utils";

const MainButton = styled.button`
  display: ${({ display = "inline-block" }) => display};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  text-decoration: ${({ decoration = "none" }) => decoration};
  border: ${({ border = "none" }) => border};
  width: ${({ width }) => width};
  border-radius: ${({ radius = "5px" }) => radius};
  cursor: ${({ cursor = "pointer" }) => cursor};
  padding: ${({ padding = "8px 16px" }) => padding};
  font-size: ${({ size }) => size};
  text-align: ${({ textAlign = "center" }) => textAlign};
  line-height: ${({ lineHeight = "1em" }) => lineHeight};
  opacity: ${({ opacity = "1" }) => opacity};
  transition-duration: 0.3s;
  margin: ${({ margin }) => margin};
  background-color: ${({ background }) => background};
  box-shadow: ${({ shadow }) =>
    shadow ? shadow : "0 4px 4px -2px rgba(0, 20, 30, 0.3)"};
  color: ${({ color }) => color};
  &:disabled {
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.8;
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &.hidden {
    visibility: hidden;
  }

  &.outline {
    background: none;
    color: ${({ color }) => (color ? color : colors.NEUTRAL_75)};
    border: 1px solid ${colors.NEUTRAL_05};
    font-size: ${({ size }) => size};
    &.square {
      border-radius: 10%;
    }
    &.circle {
      border-radius: 50%;
      padding: 16px;
    }
    &.white {
      color: ${colors.BASE};
      border: 1px solid ${colors.BASE};
    }
    &.primary {
      color: ${colors.GOLD_100};
      border: 1px solid ${colors.GOLD_100};
    }
  }

  &.transparent {
    background: transparent;
    box-shadow: none;
    padding: ${({ padding = "12px" }) => padding};
    &:hover {
      box-shadow: 0 4px 4px -2px rgba(0, 20, 30, 0.3);
      border-radius: 50%;
      border: 1px solid ${colors.NEUTRAL_20};
    }
    &.delete {
      &:hover {
        color: ${colors.red};
      }
    }
  }

  &.emoji {
    box-shadow: none;
    background: transparent;
    border-radius: 50%;
    border: 1px solid transparent;
    padding: 16px;
    &:hover {
      border: 1px solid ${colors.NEUTRAL_20};
    }
    &.delete {
      &:hover {
        border: 1px solid ${colors.NEUTRAL_20};
        color: ${colors.ERROR};
      }
    }
  }

  &.default {
    box-shadow: none;
    background: none;
    border-radius: 0;
    border: none;
    padding: ${({ padding = "16px" }) => padding};
    &.hover {
      &:hover {
        border: 1px solid ${colors.NEUTRAL_20};
        color: ${(color) => color};
      }
    }
  }
`;

const ButtonIcon = ({ kind, changeIcon, defaultIcon, ...props }) => {
  // changeIcon is rendered on hover
  // defaultIcon is the default icon rendered
  const [hovered, setHovered] = useState(false);
  return (
    <MainButton
      className={kind}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      {...props}
    >
      {" "}
      {hovered ? changeIcon : defaultIcon}{" "}
    </MainButton>
  );
};

export default ButtonIcon;
