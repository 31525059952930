import styled from "styled-components";
import PropTypes from "prop-types";
import { colors, sizing, media } from "utils";

export const TableContainer = styled.div``;

export const TableWrapper = styled.table`
  height: 100%;
  width: 240%;
  border-right: 32px solid transparent;
  @media (min-width: 540px) {
    width: 140%;
  }
  ${media.tablet`
    width: 100%;
    border-right: none;
  `}
`;

export const TableHead = styled.thead`
  justify-content: space-between;
`;

export const HeaderRow = styled.tr`
  margin-top: 16px;
  margin-bottom: 16px;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  margin: auto;
  height: 32px;
  width: 100%;
`;

export const TableRow = styled.tr`
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin: auto;
  width: 100%;
`;

export const ColumnHeader = styled.th`
  font-weight: normal;
  color: ${colors.NEUTRAL_75};
  font-size: ${sizing._100};
  font-weight: bold;
  border-bottom: 1px solid ${colors.NEUTRAL_05};
  padding-bottom: 8px;
  ${media.tablet`
    font-size: ${sizing._150};
  `}
  &:last-of-type {
    position: relative;
    &:hover {
      &:after {
        content: "Values in this column will be 0% for your first month of data since there is no previous month's data for comparison";
        background: ${colors.WHITE};
        z-index: 2;
        border-radius: 4px;
        padding: 12px;
        font-size: 14px;
        position: absolute;
        top: -120px;
        right: 0;
        width: 150px;
        text-align: center;
        font-weight: normal;
        box-shadow: rgba(0, 20, 30, 0.2) 0px 4px 4px -2px;
      }
    }
  }
`;

export const RowData = styled.td`
  font-size: 1.4rem;
  padding: 10px 0;
  color: ${colors.NEUTRAL_75};
  border-bottom: ${(props) =>
    props.borderBottom ? `1px solid ${colors.NEUTRAL_05}` : "none"};
`;

RowData.propTypes = {
  borderBottom: PropTypes.bool,
};

RowData.defaultProps = {
  borderBottom: true,
};
