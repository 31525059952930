// This is a hook like a regular useEffect but doesn't run on first load

import { useEffect, useRef } from "react";

export default function useOnUpdateEffect(callback, dependencies) {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    return callback();
    // eslint-disable-next-line
  }, [...dependencies]);
}
