const calculateSumValue = (arr, value_key) => {
  // Adds all the values of a given type (ie Sessions, Page Views, Users)
  // and returns the total
  let sum = 0;
  arr.forEach((object) => {
    if (object[value_key]) {
      sum = sum + object[value_key];
    } else {
      return;
    }
  });
  return sum;
};

export default calculateSumValue;
