import styled from "styled-components";
import { colors } from "utils";

export default styled.div`
  border-radius: 4px;
  width: 100%;
  height: ${({ height = "300px" }) => height};
  min-height: 300px;
  margin-bottom: 24px;
  position: relative;
  box-shadow: -2px 5px 9px ${colors.NEUTRAL_20};
  border-radius: 4px;
  overflow-y: scroll;

  &.overview {
    height: 530px;
  }

  &.spotlight {
    height: 400px;
  }

  &.hide {
    display: none;
  }

  &:before {
    content: "Checking for latest news and updates...";
    position: absolute;
    top: 10%;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    width: 100%;
  }
`;
