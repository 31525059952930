import React from "react";
import { Text } from "components";
import * as Styled from "./Footer.styled";
import { ReactComponent as Facebook } from "assets/icons/facebookCircle.svg";
import { ReactComponent as Twitter } from "assets/icons/twitter.svg";
import { ReactComponent as YouTube } from "assets/icons/youtube.svg";
import { getYear } from "date-fns";

const Footer = () => {
  return (
    <Styled.Wrapper>
      <Styled.SocialMediaWrapper align="center" justify="center">
        <a href="https://www.facebook.com/Legacycom">
          <Facebook />
        </a>
        <a href="https://twitter.com/LegacyObits">
          <Twitter />
        </a>
        <a href="https://www.youtube.com/legacycom">
          <YouTube />
        </a>
      </Styled.SocialMediaWrapper>
      <Styled.SmallLogo />
      <Styled.LinkWrapper justify="center">
        <a href="https://www.legacy.com/contact/">
          <Text line="1" kind="f7" color="BASE">
            Contact Us
          </Text>
        </a>
        <a href="https://www.legacy.com/faq/">
          <Text line="1" kind="f7" color="BASE">
            FAQ
          </Text>
        </a>
        <a href="https://www.legacy.com/privacy-policy/">
          <Text line="1" kind="f7" color="BASE">
            Privacy Policy
          </Text>
        </a>
        <a href="https://www.legacy.com/terms-of-use/">
          <Text line="1" kind="f7" color="BASE">
            Terms of Use
          </Text>
        </a>
      </Styled.LinkWrapper>
      <Text color="BASE">
        ©{getYear(new Date())} Legacy.com All rights reserved.
      </Text>
    </Styled.Wrapper>
  );
};

export default Footer;
