import React, { Suspense } from "react";
import { Router } from "@reach/router";
import "normalize.css";
import "typeface-open-sans";
import AuthProvider from "./providers/AuthProvider";
import LoadingPage from "./pages/LoadingPage";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const SuperAdmin = React.lazy(() => import("./pages/superAdmin"));
const Corporate = React.lazy(() => import("./pages/Corporate"));
const LeadPartner = React.lazy(() => import("./pages/LeadPartner"));
const Login = React.lazy(() => import("./pages/Login"));
const TCs = React.lazy(() => import("./pages/TermsAndConditions"));

const App = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<LoadingPage />}>
        <Router>
          <Login path="/" />
          <Dashboard path="dashboard" />
          <SuperAdmin path="admin/*" />
          <Corporate path="corporate/dashboard" />
          <LeadPartner path="lead-partner/dashboard" />
          <TCs path="terms_and_conditions/:fh_id/:parent_fh_id" />
        </Router>
      </Suspense>
    </AuthProvider>
  );
};

export default App;
