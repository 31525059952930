import React, { useState, useEffect } from "react";
import { Flex, Text, Dropdown, Button } from "components";
import * as Styled from "./Filters.styled";
import { states, subscriptionTypes } from "consts";
import { RiCloseLine } from "react-icons/ri";
import { colors, search } from "utils";

const FuneralHomesFilter = ({
  filterConfig: {
    uniqueOccupiedStates,
    isParentHome,
    setIsParentHome,
    contractSigned,
    setContractSigned,
    selectedState,
    setSelectedState,
    subFilter,
    setSubFilter,
  },
  fhPagination: { setPagination },
  originalData,
  setData,
}) => {
  // state
  const [showAllStates, setShowAllStates] = useState(false);

  // useEffect
  useEffect(() => {
    setData(originalData);
    // eslint-disable-next-line
  }, [originalData]);

  // methods
  const removeAllFilters = () => {
    clearContract();
    clearState();
    clearSubs();
  };
  const clearContract = () => {
    document.getElementById("yes").checked = false;
    document.getElementById("no").checked = false;
    setContractSigned(null);
    clearIsParentHome();
    clearSubs();
    setPagination(1);
  };
  const clearIsParentHome = () => {
    setIsParentHome("");
    setPagination(1);
  };
  const clearState = () => {
    if (selectedState) {
      const topStateSelection = document.getElementById(
        `top_selected_state_${selectedState}`
      );
      if (topStateSelection) topStateSelection.checked = false;
      const fullStateSelection = document.getElementById(
        `full_selected_state_${selectedState}`
      );
      if (fullStateSelection) fullStateSelection.checked = false;
    }
    setSelectedState(null);
    setPagination(1);
  };
  const clearSubs = () => {
    const sub = document.getElementById(`subscription_type_${subFilter}`);
    if (sub) sub.checked = false;
    setSubFilter(null);
    setPagination(1);
  };
  const selectFromTopStates = (e, el) => {
    e.stopPropagation();
    document.getElementsByName("full_selected_state").forEach((_) => {
      _.checked = false;
    });
    setSelectedState(el.state);
  };
  const selectFromAllStates = (e, el) => {
    e.stopPropagation();
    document.getElementsByName("top_selected_state").forEach((_) => {
      _.checked = false;
    });
    setSelectedState(el.state);
  };

  return (
    <Styled.Filter>
      <Flex
        width="100%"
        position="relative"
        borderBottom={`2px solid ${colors.NEUTRAL_20}`}
      >
        <Styled.FilterTitle>Filters</Styled.FilterTitle>
        {(contractSigned !== null ||
          isParentHome !== "" ||
          selectedState !== null ||
          subFilter) && (
          <Button
            position="absolute"
            right="0"
            as="span"
            kind="link"
            size="12px"
            padding="0"
            width="95px"
            onClick={removeAllFilters}
          >
            remove all filters
          </Button>
        )}
      </Flex>
      <Flex column width="100%" position="relative">
        <Styled.FilterTitle>Contract Signed</Styled.FilterTitle>
        {contractSigned !== null && (
          <Button
            position="absolute"
            right="0"
            top="10px"
            as="span"
            kind="link"
            size="12px"
            padding="0"
            onClick={clearContract}
          >
            clear
          </Button>
        )}
        <Flex width="50%" justify="space-around" margin="0 0 12px 0">
          <Styled.Checkbox className={"center"}>
            <input
              onChange={(e) => {
                e.stopPropagation();
                setContractSigned(true);
              }}
              type="radio"
              id="yes"
              name="selected_contract_signed"
              value={contractSigned || null}
            />
            {/* eslint-disable-next-line */}
            <img />
            <Text
              display="inline-block"
              htmlFor="yes"
              as="label"
              className="f8 b"
              margin="0 2px 0 0"
            >
              Yes
            </Text>
          </Styled.Checkbox>
          <Styled.Checkbox className="center">
            <input
              onChange={(e) => {
                e.stopPropagation();
                setIsParentHome("");
                setContractSigned(false);
                clearSubs();
              }}
              type="radio"
              id="no"
              name="selected_contract_signed"
              value={contractSigned || null}
            />
            {/* eslint-disable-next-line */}
            <img />
            <Text
              display="inline-block"
              htmlFor="no"
              as="label"
              className="f8 b"
              margin="0 2px 0 0"
            >
              No
            </Text>
          </Styled.Checkbox>
        </Flex>
      </Flex>
      {contractSigned && (
        <Flex column width="100%" margin="0 0 12px 0">
          <Styled.FilterTitle>Home Type</Styled.FilterTitle>
          <Flex position="relative">
            <Dropdown
              expanded
              width="200px"
              defaultOption="ALL"
              options={[
                { value: true, optionName: "Parent Home" },
                { value: false, optionName: "Child Home" },
              ]}
              value={isParentHome === null ? "ALL" : isParentHome}
              onChange={(e) => {
                setIsParentHome(e.target.value);
              }}
            />
            {isParentHome && (
              <Styled.ParentClear onClick={clearIsParentHome}>
                <RiCloseLine />
              </Styled.ParentClear>
            )}
          </Flex>
        </Flex>
      )}
      {contractSigned && (
        <Flex
          column
          width="100%"
          position="relative"
          margin="0 0 12px 0"
          borderTop={`2px solid ${colors.NEUTRAL_20}`}
        >
          <Styled.FilterTitle>Subscription Type</Styled.FilterTitle>
          {subFilter !== null && (
            <Button
              position="absolute"
              right="0"
              top="10px"
              as="span"
              kind="link"
              size="12px"
              padding="0"
              onClick={clearSubs}
            >
              clear
            </Button>
          )}
          {subscriptionTypes.map((el, key) => {
            return (
              <Styled.Checkbox key={key}>
                <input
                  onChange={(e) => {
                    setPagination(1);
                    setContractSigned(true);
                    document.getElementById("yes").checked = true;
                    setIsParentHome(true);
                    setSubFilter(e.target.value);
                  }}
                  type="radio"
                  id={`subscription_type_${el.value}`}
                  name="subscription_type"
                  value={el.value}
                />
                {/* eslint-disable-next-line */}
                <img />
                <Text
                  display="inline-block"
                  htmlFor={`subscription_type_${el.value}`}
                  as="label"
                  className="f8 b ellipsis"
                  margin="0 2px 0 0"
                >
                  {el?.label}
                </Text>
              </Styled.Checkbox>
            );
          })}
        </Flex>
      )}
      <Flex
        column
        width="100%"
        position="relative"
        margin="0 0 12px 0"
        borderTop={`2px solid ${colors.NEUTRAL_20}`}
      >
        <Styled.FilterTitle>State</Styled.FilterTitle>
        {selectedState !== null && (
          <Button
            position="absolute"
            right="0"
            top="10px"
            as="span"
            kind="link"
            size="12px"
            padding="0"
            onClick={clearState}
          >
            clear
          </Button>
        )}
        <Flex column width="100%" justify="flex-start">
          {uniqueOccupiedStates
            ?.sort((a, b) => b.count - a.count)
            .slice(0, 5)
            .map((el, key) => {
              return (
                <Styled.Checkbox key={key}>
                  <input
                    onChange={(e) => selectFromTopStates(e, el)}
                    type="radio"
                    id={`top_selected_state_${el.state}`}
                    name="top_selected_state"
                    value={el.state}
                  />
                  {/* eslint-disable-next-line */}
                  <img />
                  <Text
                    display="inline-block"
                    htmlFor={`top_selected_state_${el.state}`}
                    as="label"
                    className="f8 b ellipsis"
                    margin="0 2px 0 0"
                  >
                    {`${search(states, el.state, "object")?.label}`}
                  </Text>
                  <span>{`(${el.count})`}</span>
                </Styled.Checkbox>
              );
            })}
        </Flex>
        <Flex column align="flex-start" width="100%" margin="0 0 12px 0">
          {showAllStates && (
            <Flex
              column
              width="100%"
              justify="flex-start"
              margin="12px 0 0 0"
              padding="12px 0 0 0"
              borderTop={`2px solid ${colors.NEUTRAL_20}`}
            >
              {uniqueOccupiedStates
                ?.sort((a, b) => {
                  const itemA = search(states, a.state, "object")?.label;
                  const itemB = search(states, b.state, "object")?.label;
                  return itemA?.localeCompare(itemB);
                })
                .map((el, key) => {
                  return (
                    <Styled.Checkbox key={key}>
                      <input
                        onChange={(e) => selectFromAllStates(e, el)}
                        type="radio"
                        id={`full_selected_state_${el.state}`}
                        name="full_selected_state"
                        value={el.state}
                      />
                      {/* eslint-disable-next-line */}
                      <img />
                      <Text
                        display="inline-block"
                        htmlFor={`full_selected_state_${el.state}`}
                        as="label"
                        className="f8 b ellipsis"
                        margin="0 2px 0 0"
                      >
                        {`${search(states, el.state, "object")?.label}`}
                      </Text>
                      <span>{`(${el.count})`}</span>
                    </Styled.Checkbox>
                  );
                })}
            </Flex>
          )}
          {!showAllStates ? (
            <Button
              as="span"
              kind="link"
              size="12px"
              padding="0"
              margin="10px 0 0 0"
              onClick={() => setShowAllStates(true)}
            >
              Show All States
            </Button>
          ) : (
            <Button
              as="span"
              kind="link"
              size="12px"
              padding="0"
              margin="10px 0 0 0"
              onClick={() => setShowAllStates(false)}
            >
              Show Less
            </Button>
          )}
        </Flex>
      </Flex>
      ;
    </Styled.Filter>
  );
};

export default FuneralHomesFilter;
