import styled from "@emotion/styled";
import { colors } from "utils";

export const Drawer = styled.div`
  background: ${colors.NEUTRAL_75};
  margin-top: -2px;
  padding: 12px;
`;

export const DrawerItem = styled.div`
  min-width: 200px;
  float: left;
  margin: 12px 0px;
  color: ${colors.WHITE};
`;
