const sortByDate = (arr) => {
  if (arr.length <= 1) {
    return arr;
  } else if (arr[0].date) {
    const sortedArr = arr.sort(
      (a, b) =>
        // Sorts array by day of the month in ascending order
        parseInt(
          `${a?.date?.[a.date.length - 2]}${a?.date?.[a.date.length - 1]}`
        ) -
        parseInt(
          `${b?.date?.[b.date.length - 2]}${b?.date?.[b.date.length - 1]}`
        )
    );
    return sortedArr;
  } else if (arr[0].order_date) {
    const sortedArr = arr.sort(
      (a, b) =>
        // Sorts array by day of the month in ascending order
        parseInt(
          `${a?.order_date?.[a.order_date.length - 2]}${
            a?.order_date?.[a.order_date.length - 1]
          }`
        ) -
        parseInt(
          `${b?.order_date?.[b.order_date.length - 2]}${
            b?.order_date?.[b.order_date.length - 1]
          }`
        )
    );
    return sortedArr;
  } else if (arr[0].month) {
    return arr
      .sort((a, b) => a.year - b.year)
      .sort((a, b) => a.month - b.month);
  } else if (arr[0].utc) {
    return arr.sort((a, b) => a.utc - b.utc);
  }
};

export default sortByDate;
